import { Test } from '@analyzer/client';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

interface RunTestDialogProps {
  test?: Test;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export function RunTestDialog({ test, open, onClose, onConfirm }: RunTestDialogProps) {
  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  const handleClose = () => {
    onClose();
  };

  if (!test) return null;

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Run test?</DialogTitle>
      <DialogContent>
        <DialogContentText>Are you sure you want to run test {test.name} ?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleConfirm}>Confirm</Button>
      </DialogActions>
    </Dialog>
  );
}
