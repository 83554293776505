import { useQuery } from '@tanstack/react-query';
import { useService } from '../providers/ServiceProvider.js';
import { Alert, AlertIdentifier, AnalysisIdentifier } from '@analyzer/client';
import { FRONTEND_SERVICE_URL } from '../settings.js';

export function alertListQuery(analysisId: AnalysisIdentifier) {
  const client = useService();
  return useQuery<AlertIdentifier[], Error>({
    queryKey: ['alert-list', analysisId],
    queryFn: async () => {
      return await client.alertList(analysisId);
    }
  });
}

export function alertReadQuery(alertId: AlertIdentifier) {
  return useQuery<Alert, Error>({
    queryKey: ['alert', alertId],
    queryFn: async () => {
      const response = await fetch(`${FRONTEND_SERVICE_URL}/public/alert/${alertId}`);
      if (!response.ok) {
        throw new Error(`An error occurred: ${response.statusText}`);
      }

      return (await response.json()).alert;
    },
    enabled: !!alertId
  });
}
