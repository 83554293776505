import { URLUtils } from '@analyzer/utilities/lib/url.js';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';

interface SummarizedLinkProps {
  url: string;
  title?: string;
}

function SummarizedLink({ url, title }: SummarizedLinkProps) {
  const displayUrl = URLUtils.summarizeURL(url);

  return (
    <Tooltip title={url}>
      <Link href={url} target="_blank" rel="noopener noreferrer">
        {title ?? displayUrl}
      </Link>
    </Tooltip>
  );
}

export default SummarizedLink;
