import { Button } from '@mui/material';
import { analysisCreateMutation } from '../../api/analysis';
import { reportCreateMutation } from '../../api/report';
import { TestIdentifier } from '@analyzer/client';

export function NewAnalysisComponent({ testId }: { testId: TestIdentifier }) {
  const analysis = analysisCreateMutation();
  const report = reportCreateMutation();

  async function handler() {
    const analysisId = await analysis.mutateAsync({ testId });
    await report.mutateAsync({ testId, analysisId });
  }

  return (
    <Button disabled={analysis.isLoading} onClick={handler}>
      Analyze
    </Button>
  );
}
