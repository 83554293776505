import { TestIdentifier } from '@analyzer/client';
import AssessmentIcon from '@mui/icons-material/Assessment';
import {
  Alert,
  Avatar,
  CircularProgress,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText
} from '@mui/material';
import { Link } from 'react-router-dom';
import { reportListQuery } from '../../api/report';
import { PaginatedList } from '../generic/PaginatedList';
import { decodeObjectId } from '../utils';

function ReportListElement({ data }: { data: any }) {
  return (
    <ListItem disablePadding>
      <ListItemButton component={Link} to={`/report/${data.id}`}>
        <ListItemAvatar>
          <Avatar>
            <AssessmentIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={data.id} secondary={`Created ${data.date}`} />
      </ListItemButton>
    </ListItem>
  );
}

export function ReportsList({ testId }: { testId: TestIdentifier }) {
  const query = reportListQuery(testId);
  if (query.isLoading) return <CircularProgress />;
  if (query.isError) return <Alert severity="error">{`Error: ${query.error.message}`}</Alert>;

  return <PaginatedList data={query.data.map(decodeObjectId)} ListElement={ReportListElement} />;
}
