import { useQuery } from '@tanstack/react-query';
import { FRONTEND_SERVICE_URL } from '../settings.js';

export function metricsReadQuery() {
  return useQuery<any, Error>({
    queryKey: ['metrics'],
    queryFn: async () => {
      console.log(FRONTEND_SERVICE_URL);
      const response = await fetch(`${FRONTEND_SERVICE_URL}/metrics`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    }
  });
}
