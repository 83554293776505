import { Alert, ProjectTuple, TestIdentifier } from '@analyzer/client';
import BarChartIcon from '@mui/icons-material/BarChart';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import WarningIcon from '@mui/icons-material/Warning';
import { Backdrop, Badge, Box, CircularProgress } from '@mui/material';
import { analysisListQuery, analysisReadQuery } from '../api/analysis.js';
import { projectListQuery } from '../api/project.js';
import { testListQuery } from '../api/test.js';
import { AlertSeverity } from '@analyzer/core/lib/analyzer/plugins/index.js';

function Header({ alerts, score }: { alerts: Alert[]; score: number }) {
  const severityCounts = alerts.reduce((acc, alert) => {
    acc[alert.severity] = (acc[alert.severity] || 0) + 1;
    return acc;
  }, {} as Record<AlertSeverity, number>);

  return (
    <Box display="flex" justifyContent="space-between">
      <Box display="flex" justifyContent="space-between" gap={3}>
        <Badge showZero badgeContent={severityCounts['high'] || 0} color="error">
          <ErrorIcon />
        </Badge>
        <Badge showZero badgeContent={severityCounts['medium'] || 0} color="warning">
          <WarningIcon />
        </Badge>
        <Badge showZero badgeContent={severityCounts['low'] || 0} color="info">
          <LowPriorityIcon />
        </Badge>
        <Badge showZero badgeContent={severityCounts['info'] || 0} color="primary">
          <InfoIcon />
        </Badge>
        <Badge showZero badgeContent={(score * 100).toFixed()} color="primary">
          <BarChartIcon />
        </Badge>
      </Box>
    </Box>
  );
}

function AlertList({ alerts }: { alerts: Alert[] }) {
  return (
    <div>
      {alerts.map((alert, index) => (
        <div key={index}>
          <p>Type: {alert.type}</p>
          <p>Severity: {alert.severity}</p>
          <p>Description: {alert.description}</p>
        </div>
      ))}
    </div>
  );
}

function Analysis({ analysisId }: { analysisId: string }) {
  const { data, isLoading, isError } = analysisReadQuery(analysisId);

  if (isLoading || isError || !data) {
    return null;
  }

  return (
    <>
      <Header alerts={data.alerts} score={data.score} />
      <AlertList alerts={data.alerts} />
    </>
  );
}

function Test({ testId }: { testId: TestIdentifier }) {
  const { data: analysisIds, isLoading, isError } = analysisListQuery(testId);

  if (isLoading || isError || !analysisIds?.length) {
    return null;
  }

  // Here we pick the last analysis for the test
  const lastAnalysisId = analysisIds[0];

  return <Analysis analysisId={lastAnalysisId} />;
}

function Project({ project }: { project: ProjectTuple }) {
  const { data: testIds, isLoading, isError } = testListQuery(project.id);

  if (isLoading || isError || !testIds?.length) {
    return null;
  }

  return (
    <div>
      <h2>{project.project.name}</h2>
      {testIds.map(({ id }) => (
        <Test key={id} testId={id} />
      ))}
    </div>
  );
}

export function AlertsView() {
  const { data: projects, isLoading, isError } = projectListQuery();

  if (isLoading) {
    return (
      <Backdrop open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  if (isError || !projects) {
    return <div>Error occurred while fetching projects</div>;
  }

  return (
    <>
      {projects.map((project) => (
        <Project key={project.id} project={project} />
      ))}
    </>
  );
}
