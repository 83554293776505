import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { AnalysisView } from './pages/AnalysisView';
import { Dashboard } from './pages/Dashboard';
import { ErrorPage } from './pages/ErrorPage';
import { Login } from './pages/Login';
import { Logout } from './pages/Logout';
import { MainView } from './pages/MainView';
import { ProjectView } from './pages/ProjectView';
import { ReportView } from './pages/ReportView';
import { SnapshotView } from './pages/SnapshotView';
import { TestView } from './pages/TestView';
import { useAuth } from './providers/AuthProvider';
import { ServiceProvider } from './providers/ServiceProvider';
import { ProjectsView } from './pages/ProjectsView';
import { AlertsView } from './pages/AlertsView.js';
import { AlertView } from './pages/AlertView.js';

// Create public routes
const publicRouter = createBrowserRouter([
  {
    path: '/',
    element: <Login />
  },
  {
    path: '/alert/:alertId',
    element: <AlertView />
  }
]);

// Create protected routes.
const protectedRouter = createBrowserRouter([
  {
    path: '/',
    element: <Dashboard />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <MainView />
      },
      {
        path: '/logout',
        element: <Logout />
      },
      {
        path: '/projects',
        element: <ProjectsView />
      },
      {
        path: '/alerts',
        element: <AlertsView />
      },
      {
        path: '/project/:projectId',
        element: <ProjectView />
      },
      {
        path: '/test/:testId',
        element: <TestView />
      },
      {
        path: '/snapshot/:snapshotId',
        element: <SnapshotView />
      },
      {
        path: '/analysis/:analysisId',
        element: <AnalysisView />
      },
      {
        path: '/report/:reportId',
        element: <ReportView />
      },
      {
        path: '/alert/:alertId',
        element: <AlertView />
      }
    ]
  }
]);

export function Routes() {
  const { authenticated } = useAuth();
  if (!authenticated) {
    return <RouterProvider router={publicRouter} />;
  }

  return (
    <ServiceProvider>
      <RouterProvider router={protectedRouter} />
    </ServiceProvider>
  );
}
