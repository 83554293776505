import { Project, ProjectIdentifier, ProjectTuple } from '@analyzer/client';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useService } from '../providers/ServiceProvider';

export function projectReadQuery(projectId: ProjectIdentifier | null) {
  const client = useService();
  return useQuery<Project, Error>({
    queryKey: ['project-read', projectId],
    queryFn: async () => {
      return client.projectRead(projectId!);
    },
    enabled: projectId !== null
  });
}

export function projectListQuery() {
  const client = useService();
  return useQuery<ProjectTuple[], Error>({
    queryKey: ['project-list'],
    queryFn: async () => {
      return await client.projectListHelper();
    }
  });
}

interface ProjectCreateArguments {
  name: string;
  description: string;
}

export function projectCreateMutation() {
  const client = useService();
  const queryClient = useQueryClient();
  return useMutation<ProjectIdentifier, Error, ProjectCreateArguments>({
    mutationFn: async ({ name, description }) => {
      return await client.projectCreate(name, description);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['project-list']
      });
    }
  });
}

interface ProjectUpdateArguments {
  projectId: ProjectIdentifier;
  name?: string;
  description?: string;
}

export function projectUpdateMutation() {
  const client = useService();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ projectId, name, description }: ProjectUpdateArguments) => {
      return await client.projectUpdate(projectId, { name, description });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['project-list']
      });
    }
  });
}

interface ProjectDestroyArguments {
  projectId: ProjectIdentifier;
}

export function projectDestroyMutation() {
  const client = useService();
  const queryClient = useQueryClient();

  return useMutation<boolean, Error, ProjectDestroyArguments>({
    mutationFn: async ({ projectId }) => {
      return await client.projectDestroy(projectId);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['project-list']
      });
    }
  });
}
