import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useService } from '../providers/ServiceProvider';
import { Test, TestIdentifier } from '@analyzer/client';
import { ProjectIdentifier, ReportIdentifier } from '@analyzer/client';

export function testReadQuery(testId: TestIdentifier | null) {
  const client = useService();
  return useQuery<Test, Error>({
    queryKey: ['test-read', testId],
    queryFn: async () => {
      return client.testRead(testId!);
    },
    enabled: testId !== null
  });
}

export function testListQuery(projectId: ProjectIdentifier) {
  const client = useService();
  return useQuery<{ id: string; test: Test }[], Error>({
    queryKey: ['test-list', projectId],
    queryFn: async () => {
      const tests = await client.testList(projectId);
      const promises = tests.map(async (test) => ({ id: test, test: await client.testRead(test) }));
      const results = await Promise.all(promises);
      return results;
    }
  });
}

interface TestUpdateArguments {
  testId: TestIdentifier;
  name?: string;
  target?: string;
  actions?: string;
  initScript?: string;
}

export function testUpdateMutation() {
  const client = useService();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ testId, name, target, actions, initScript }: TestUpdateArguments) => {
      return await client.testUpdate(testId, name, target, actions, initScript);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['test-list']
      });
    }
  });
}

interface TestCreateArguments {
  project: ProjectIdentifier;
  name: string;
  target: string;
  actions?: string;
  initScript?: string;
}

export function testCreateMutation() {
  const client = useService();
  const queryClient = useQueryClient();
  return useMutation<TestIdentifier, Error, TestCreateArguments>({
    mutationFn: async ({ project, name, target, actions, initScript }) => {
      return await client.testCreate(project, name, target, actions, initScript);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['test-list']
      });
    }
  });
}

interface TestRunArguments {
  testId: TestIdentifier;
}

export function testDestroyMutation() {
  const client = useService();
  const queryClient = useQueryClient();

  return useMutation<boolean, Error, TestRunArguments>({
    mutationFn: async ({ testId }) => {
      return await client.testDestroy(testId);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['test-list']
      });
    }
  });
}

export function testRunMutation() {
  const client = useService();
  return useMutation<ReportIdentifier, Error, TestRunArguments>({
    mutationFn: async ({ testId }) => {
      await client.snapshotCreate(testId);
      const analysisId = await client.analysisCreate(testId);
      return await client.reportCreate(analysisId);
    }
  });
}
