import { AnalysisIdentifier, Report, ReportIdentifier, TestIdentifier } from '@analyzer/client';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useService } from '../providers/ServiceProvider';

export function reportReadQuery(reportId: ReportIdentifier) {
  const client = useService();
  return useQuery<Report, Error>({
    queryKey: ['report-read', reportId],
    queryFn: async () => {
      return client.reportRead(reportId);
    }
  });
}

export function reportListQuery(testId: TestIdentifier) {
  const client = useService();
  return useQuery<ReportIdentifier[], Error>({
    queryKey: ['report-list', testId],
    queryFn: async () => {
      return client.reportList(testId);
    }
  });
}

interface ReportCreateArguments {
  testId: TestIdentifier;
  analysisId: AnalysisIdentifier;
}

export function reportCreateMutation() {
  const client = useService();
  const queryClient = useQueryClient();

  return useMutation<ReportIdentifier, Error, ReportCreateArguments>({
    mutationFn: async ({ analysisId }) => {
      return await client.reportCreate(analysisId);
    },
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries({
        queryKey: ['report-list', variables.testId]
      });
    }
  });
}
