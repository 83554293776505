import { useState } from 'react';

export function useModalStateHandler<T>(state: T) {
  type ObjectKeys = keyof T;
  const [modalState, setModalState] = useState(state);

  const openModal = (modalName: ObjectKeys) => {
    setModalState({ ...modalState, [modalName]: true });
  };

  const closeModal = (modalName: ObjectKeys) => {
    setModalState({ ...modalState, [modalName]: false });
  };

  return {
    open: openModal,
    close: closeModal,
    state: modalState
  };
}
