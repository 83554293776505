import { useRouteError } from 'react-router-dom';

export function ErrorPage() {
  const error = useRouteError() as any;

  return (
    <main className="container">
      <article>
        <h1>Oops!</h1>
        <p>Sorry, an unexpected error has occurred.</p>
        <p>
          <i>{error.statusText || error.message}</i>
        </p>
      </article>
    </main>
  );
}
