import { Project } from '@analyzer/client';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';

interface EditProjectModalProps {
  project?: Project;
  open: boolean;
  onClose: () => void;
  onConfirm: (project: Partial<Project>) => void;
}

interface EditProjectFormValues {
  name: string;
  description: string;
}

export function EditProjectModal({ project, open, onClose, onConfirm }: EditProjectModalProps) {
  const { register, handleSubmit } = useForm<EditProjectFormValues>({ values: project });

  const handleConfirm = handleSubmit(async ({ name, description }) => {
    onConfirm({ name, description });
    onClose();
  });

  const handleClose = () => {
    onClose();
  };

  if (!project) return null;

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Edit project</DialogTitle>
      <form onSubmit={handleConfirm}>
        <DialogContent>
          <Stack spacing={2}>
            <TextField placeholder="Name" inputProps={register('name', { required: true })} />
            <TextField placeholder="Description" inputProps={register('description', { required: true })} />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Update</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
