import { Button } from '@mui/material';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { projectDestroyMutation } from '../../api/project';

export function DestroyProjectComponent({ projectId }: any) {
  const [destroyed, setDestroyed] = useState(false);

  const { isLoading, mutate } = projectDestroyMutation();

  function handler() {
    mutate({ projectId });
    setDestroyed(true);
  }

  if (destroyed) {
    return <Navigate to="/" />;
  }

  return (
    <Button disabled={isLoading} aria-busy={isLoading} onClick={handler}>
      Destroy
    </Button>
  );
}
