import { List, Pagination, Stack } from '@mui/material';
import { ComponentType, useState } from 'react';

export function PaginatedList({
  data,
  ListElement
}: {
  data: any[];
  ListElement: ComponentType<{ data: any }>;
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  const pageCount = Math.floor(data.length / 10) + 1;
  const start = (currentPage - 1) * 10;
  const end = start + 10;
  const currentSlice = data.slice(start, end);

  const renderedElements = currentSlice.map((data, index) => <ListElement key={index} data={data} />);

  return (
    <Stack>
      <List dense={true}>{renderedElements}</List>
      <Pagination count={pageCount} page={currentPage} onChange={handleChange} shape="rounded" />
    </Stack>
  );
}
