import { Box, CircularProgress, Container, Grid, Typography } from '@mui/material';
import { metricsReadQuery } from '../api/metrics.js';
import DailyMetricsGraph from '../components/MetricsGraph.js';
import { getFormattedDateFromIsoString } from '../components/utils.js';
import { FRONTEND_BUILD_STRING, FRONTEND_SERVICE_URL } from '../settings.js';

export function MainView() {
  const { data: metrics, isLoading, isError, error } = metricsReadQuery();

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography color="error" variant="h6">
          Error: {error.message}
        </Typography>
      </Box>
    );
  }

  const formatDate = (isoString: string) => (isoString ? getFormattedDateFromIsoString(isoString) : 'N/A');

  return (
    <Container>
      <Grid container spacing={4}>
        {/* Overview */}
        <Grid item xs={12}>
          <Typography variant="h6">Overview</Typography>
          <Typography variant="body1">Service URL: {FRONTEND_SERVICE_URL}</Typography>
          <Typography variant="body1">Build String: {FRONTEND_BUILD_STRING}</Typography>
        </Grid>

        {/* Component Metrics */}
        {metrics.components &&
          Object.keys(metrics.components).map((key) => (
            <Grid item key={key} xs={4}>
              <Typography variant="h6">{key.toUpperCase()}</Typography>
              <Typography variant="body2">Start: {formatDate(metrics.components[key].start)}</Typography>
              <Typography variant="body2">Stop: {formatDate(metrics.components[key].stop)}</Typography>
              <Typography variant="body2">Ping: {formatDate(metrics.components[key].ping)}</Typography>
              <Typography variant="body2">Errors: {metrics.components[key].errors.length}</Typography>
            </Grid>
          ))}

        {['project', 'snapshot', 'analysis', 'alert'].map((category) => (
          <Grid item key={category} xs={3}>
            <Typography variant="h6">{category.toUpperCase()}:</Typography>
            <Typography variant="body2">Count: {metrics[category].count}</Typography>
            <Typography variant="body2">First: {formatDate(metrics[category].first)}</Typography>
            <Typography variant="body2">Last: {formatDate(metrics[category].last)}</Typography>
          </Grid>
        ))}

        {/* Daily Graphs */}
        <Grid item xs={4}>
          <Typography variant="h6">Snapshots</Typography>
          <DailyMetricsGraph metrics={metrics.snapshot} />
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6">Analyses</Typography>
          <DailyMetricsGraph metrics={metrics.analysis} />
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6">Alerts</Typography>
          <DailyMetricsGraph metrics={metrics.alert} />
        </Grid>
      </Grid>
    </Container>
  );
}
