import { EthereumRPCChangeAlert } from '@analyzer/core/lib/analyzer/plugins/changes/ethereum.js';
import { EthereumTransaction } from '@analyzer/core/lib/collector/collectors/web.js';
import { Typography } from '@mui/material';
import { ethers } from 'ethers';
import { useEffect, useState } from 'react';

export type DecodedEthereumCall =
  | {
      type: 'contract-creation';
      contract: string;
    }
  | {
      type: 'contract-call';
      signature: string;
      inputs: string[];
      outputs: string[];
    }
  | {
      type: 'sign-operation';
      signer: string;
      data: string;
    };

// TODO: This is a temporary API key. Replace this with your own API key.
const ETHERSCAN_API_KEY = 'RPT4XCJTX5UNN9C2FBHQAERVE83WWMZD3D'; // replace this with your actual Etherscan API key

async function getContractAbi(address: string): Promise<string | null> {
  const url = `https://api.etherscan.io/api?module=contract&action=getabi&address=${address}&apikey=${ETHERSCAN_API_KEY}`;
  const response = await fetch(url);
  const data = await response.json();
  if (data.status !== '1' || data.message === 'NOTOK') return Promise.resolve(null);
  return Promise.resolve(data.result as string);
}

async function getMethodSignature(hexSignature: string) {
  const url = `https://www.4byte.directory/api/v1/signatures/?format=json&hex_signature=${hexSignature}`;
  const response = await fetch(url);
  const data = await response.json();
  if (data.count === 0) return Promise.resolve(null);
  return Promise.resolve(data.results[0].text_signature as string);
}

async function getOrGuessContractAbi(transaction: ethers.Transaction) {
  const contractAbi = await getContractAbi(transaction.to!);
  if (contractAbi) return contractAbi;

  const methodSignature = await getMethodSignature(transaction.data.slice(10));
  if (!methodSignature) return null;

  const functionFragment = ethers.utils.FunctionFragment.from(methodSignature);
  return [functionFragment];
}

async function decodeTransaction(transaction: ethers.Transaction): Promise<DecodedEthereumCall> {
  if (!transaction.to) {
    return {
      type: 'contract-creation',
      contract: transaction.data
    };
  }

  const contractAbi = await getOrGuessContractAbi(transaction);
  if (!contractAbi) {
    return {
      type: 'contract-call',
      signature: 'unknown',
      inputs: [],
      outputs: []
    };
  }

  const contractInterface = new ethers.utils.Interface(contractAbi);
  const parsedTransaction = contractInterface.parseTransaction({
    data: transaction.data
  });

  const signature = ethers.utils.FunctionFragment.prototype.format.call(
    parsedTransaction.functionFragment,
    'full'
  );

  return {
    type: 'contract-call',
    signature,
    inputs: parsedTransaction.args.map((arg) => JSON.stringify(arg)),
    outputs: []
  };
}

function decodeSignOperation(call: EthereumTransaction): DecodedEthereumCall {
  return {
    type: 'sign-operation',
    signer: call.params[0] as string,
    data: call.params[1] as string
  };
}

// This will convert an RPC call to a structure that can be shared
// with the frontend.
async function decodeEthereumCall(call: EthereumTransaction): Promise<DecodedEthereumCall> {
  switch (call.method) {
    case 'eth_signTransaction':
    case 'eth_sendTransaction':
      return decodeTransaction(call.params[0] as ethers.Transaction);

    case 'eth_sendRawTransaction':
      const rawTransaction = ethers.utils.parseTransaction(call.params[0] as string);
      return decodeTransaction(rawTransaction);

    case 'eth_sign':
    case 'eth_signTypedData_v4':
    case 'eth_signTypedData_v3':
    case 'eth_signTypedData':
    case 'personal_sign':
      return decodeSignOperation(call);

    default:
      throw new Error(`Cannot decode ${call.method}`);
  }
}

function renderDecodedEthereumCall(data: DecodedEthereumCall) {
  switch (data.type) {
    case 'contract-creation':
      return (
        <div>
          <Typography variant="h3">Contract Creation</Typography>
          <Typography variant="body1">Contract: {data.contract}</Typography>
        </div>
      );

    case 'contract-call':
      return (
        <div>
          <Typography variant="h3">Contract Call</Typography>
          <Typography variant="body1">
            <b>Signature:</b> {data.signature}
          </Typography>
          <Typography variant="body1">Inputs:</Typography>
          <ul>
            {data.inputs.map((input, index) => (
              <li key={index}>{input}</li>
            ))}
          </ul>

          <Typography variant="body1">Outputs:</Typography>
          <ul>
            {data.outputs.map((output, index) => (
              <li key={index}>{output}</li>
            ))}
          </ul>
        </div>
      );

    case 'sign-operation':
      return (
        <div>
          <Typography variant="h3">Sign Operation</Typography>
          <Typography variant="body1">Signer: {data.signer}</Typography>
          <Typography variant="body1">Data: {data.data}</Typography>
        </div>
      );

    default:
      return null;
  }
}

export function renderEthereumRPCChangeAlert(alert: EthereumRPCChangeAlert) {
  const [decodedData, setDecodedData] = useState(<>Loading ...</>);
  useEffect(() => {
    async function fetchData() {
      switch (alert.kind) {
        case 'missing':
          const missing = await decodeEthereumCall(alert.missing!);
          setDecodedData(
            <>
              <Typography variant="h4">Missing transaction</Typography>
              {renderDecodedEthereumCall(missing)}
            </>
          );
          break;

        case 'additional':
          const additional = await decodeEthereumCall(alert.additional!);
          setDecodedData(
            <>
              <Typography variant="h4">Additional transaction</Typography>
              {renderDecodedEthereumCall(additional)}
            </>
          );
          break;

        case 'changed':
          const to = await decodeEthereumCall(alert.to!);
          const from = await decodeEthereumCall(alert.from!);
          // TODO: Display the two transactions side to side
          setDecodedData(
            <>
              <Typography variant="h4">Changed transaction</Typography>
              {renderDecodedEthereumCall(to)}
              {renderDecodedEthereumCall(from)}
            </>
          );
          break;
      }
    }

    void fetchData();
  }, [alert]);

  return decodedData;
}
