import { Box, Paper, Stack, Typography } from '@mui/material';
import { CredentialResponse } from 'google-one-tap';
import { useRef } from 'react';
import { useScript } from '../hooks/useScript';
import { useAuth } from '../providers/AuthProvider';

export function Login() {
  const { login } = useAuth();

  // Dynamicaly load the google identity library and render the login button.
  const loginButton = useRef(null);
  useScript('https://accounts.google.com/gsi/client', () => {
    if (!loginButton.current) return;

    window.google.accounts.id.initialize({
      client_id: '110683197597-pp6glkrupesg4q2kndprmosj98m8fqp8.apps.googleusercontent.com',
      callback: (credentialResponse: CredentialResponse) => {
        login(credentialResponse.credential);
      }
    });

    window.google.accounts.id.renderButton(loginButton.current, { size: 'large' });
  });

  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
      <Paper elevation={10} style={{ padding: 40 }}>
        <Stack spacing={2}>
          <Typography variant="h4">Sign in</Typography>
          <Typography variant="overline">
            Please, sign in with your <strong>coinspect</strong> account.
          </Typography>
          <div ref={loginButton}></div>
        </Stack>
      </Paper>
    </Box>
  );
}
