import CrisisAlert from '@mui/icons-material/CrisisAlert.js';
import FolderIcon from '@mui/icons-material/Folder';
import HomeIcon from '@mui/icons-material/Home';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import {
  Avatar,
  Box,
  CssBaseline,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material';
import { Link, Outlet } from 'react-router-dom';
import { useAuth } from '../providers/AuthProvider';

function AppBarComponent() {
  const { user } = useAuth();

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', m: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <TroubleshootIcon sx={{ mr: 2, ml: 2 }} />
        <Typography variant="h6" component={Link} to="/" sx={{ fontFamily: 'Montserrat', color: 'inherit' }}>
          ANALYZER
        </Typography>
      </Box>
      <IconButton size="large">
        <Avatar alt={user?.name} src={user?.picture} />
      </IconButton>
    </Box>
  );
}

const menuItems = [
  { text: 'Home', icon: <HomeIcon />, path: '/' },
  { text: 'Alerts', icon: <CrisisAlert />, path: '/alerts' },
  { text: 'Projects', icon: <FolderIcon />, path: '/projects' },
  { text: 'Settings', icon: <SettingsApplicationsIcon />, path: '/settings' },
  { text: 'Logout', icon: <LogoutIcon />, path: '/logout' }
];

function NavigationMenu() {
  return (
    <>
      <List>
        {menuItems.map(({ text, icon, path }) => (
          <ListItem key={text} disablePadding>
            <ListItemButton component={Link} to={path}>
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
    </>
  );
}

function MainContent() {
  return <Outlet />;
}

export function Dashboard() {
  return (
    <>
      <CssBaseline />
      <AppBarComponent />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} md={3} lg={2}>
          <NavigationMenu />
        </Grid>

        <Grid item xs={12} sm={6} md={7} lg={8}>
          <MainContent />
        </Grid>
      </Grid>
    </>
  );
}
