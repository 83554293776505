import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';

interface Project {
  name: string;
  description: string;
}

interface Test {
  name: string;
  target: string;
}

interface NewProjectModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (project: Project, test: Test) => void;
}

interface NewProjectFormValues {
  url: string;
  name?: string;
  description?: string;
}

export function NewProjectModal({ open, onClose, onConfirm }: NewProjectModalProps) {
  const { register, handleSubmit } = useForm<NewProjectFormValues>();

  const handleConfirm = handleSubmit(async ({ url, name, description }) => {
    if (!name) name = new URL(url).hostname;
    if (!description) description = `Project for ${url}`;

    const project: Project = {
      name,
      description
    };

    const test: Test = {
      name: `Default Test`,
      target: url
    };

    onConfirm(project, test);
    onClose();
  });

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>New project</DialogTitle>
      <form onSubmit={handleConfirm}>
        <DialogContent>
          <Stack spacing={2}>
            <TextField label="URL" inputProps={register('url', { required: true })} />
            <TextField label="Name (optional)" inputProps={register('name', { required: false })} />
            <TextField
              label="Description (optional)"
              inputProps={register('description', { required: false })}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Create</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

interface NewProjectsModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (projectTestPairs: { project: Project; test: Test }[]) => void;
}

interface NewProjectsFormValues {
  urls: string;
}

const isValidUrl = (url: string): boolean => {
  try {
    void new URL(url);
    return true;
  } catch (_) {
    return false;
  }
};

export function NewProjectsModal({ open, onClose, onConfirm }: NewProjectsModalProps) {
  const { register, handleSubmit } = useForm<NewProjectsFormValues>();

  const handleConfirm = handleSubmit(({ urls }) => {
    const urlList = urls.split('\n').filter((url) => url.trim() !== '' && isValidUrl(url.trim()));
    const projectTestPairs = urlList.map((url) => ({
      project: {
        name: new URL(url).hostname,
        description: `Project for ${url}`,
        rules: []
      },
      test: {
        name: `Default Test`,
        target: url
      }
    }));

    onConfirm(projectTestPairs);
    onClose();
  });

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>Add Bulk Projects</DialogTitle>
      <form onSubmit={handleConfirm}>
        <DialogContent>
          <p>
            Enter multiple URLs, each on a new line, to create multiple projects at once. For each URL, a new
            project and an associated test will be created.
          </p>
          <Stack spacing={2}>
            <TextField label="URLs" inputProps={register('urls', { required: true })} multiline rows={14} />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Create</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
