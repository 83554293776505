import { Alert, CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import { reportReadQuery } from '../api/report';

export function ReportView() {
  const { reportId } = useParams();
  if (!reportId) throw new Error('Missing parameter');

  const report = reportReadQuery(reportId);
  if (report.isLoading) return <CircularProgress />;
  if (report.isError) return <Alert severity="error">{`Error: ${report.error.message}`}</Alert>;

  return <div dangerouslySetInnerHTML={{ __html: report.data.content }} />;
}
