import { Analysis, AnalysisIdentifier, TestIdentifier } from '@analyzer/client';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useService } from '../providers/ServiceProvider';

export function analysisReadQuery(analysisId: AnalysisIdentifier) {
  const client = useService();
  return useQuery<Analysis, Error>({
    queryKey: ['analysis-read', analysisId],
    queryFn: async () => {
      return client.analysisRead(analysisId);
    }
  });
}

export function analysisListQuery(testId: TestIdentifier) {
  const client = useService();
  return useQuery<AnalysisIdentifier[], Error>({
    queryKey: ['analysis-list', testId],
    queryFn: async () => {
      return await client.analysisList(testId);
    }
  });
}

interface AnalysisCreateArguments {
  testId: TestIdentifier;
}

export function analysisCreateMutation() {
  const client = useService();
  const queryClient = useQueryClient();
  return useMutation<AnalysisIdentifier, Error, AnalysisCreateArguments>({
    mutationFn: async ({ testId }) => {
      return await client.analysisCreate(testId);
    },
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries({
        queryKey: ['analysis-list', variables.testId]
      });
    }
  });
}
