import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

interface DailyMetricsGraphProps {
  metrics: {
    count: number;
    first: string;
    last: string;
    daily?: number[];
  };
}

function DailyMetricsGraph({ metrics }: DailyMetricsGraphProps) {
  const data = metrics.daily?.map((value, index) => ({
    day: `${index}`,
    count: value
  }));

  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart data={data}>
        <CartesianGrid />
        <XAxis dataKey="day" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="linear" dataKey="count" legendType="none" isAnimationActive={false} />
      </LineChart>
    </ResponsiveContainer>
  );
}

export default DailyMetricsGraph;
