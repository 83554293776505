import { Test as BaseTest } from '@analyzer/client';
import UploadFile from '@mui/icons-material/UploadFile';
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { useForm } from 'react-hook-form';

import { parseScript } from 'esprima';

type Test = Pick<BaseTest, 'name' | 'target' | 'actions'>;

function readFileAsync(file: File) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.onerror = reject;
    reader.readAsText(file);
  });
}

interface NewTestDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (test: Test) => void;
}

interface NewTestFormValues {
  name: string;
  target: string;
  file: FileList;
}

interface EsprimaSyntaxError {
  lineNumber: number;
  column: number;
  description: string;
}

export function NewTestDialog({ open, onClose, onConfirm }: NewTestDialogProps) {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<NewTestFormValues>();

  const handleConfirm = handleSubmit(async ({ name, target, file }) => {
    let actions;
    if (file.length) {
      actions = await readFileAsync(file[0]);

      try {
        parseScript(actions);
      } catch (error) {
        const syntaxError = error as EsprimaSyntaxError;
        setError('file', {
          type: 'syntax',
          message: `${syntaxError.description} at line ${syntaxError.lineNumber}, column ${syntaxError.column}`
        });

        return;
      }
    }

    onConfirm({ name, target, actions });
    onClose();
  });

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>New test</DialogTitle>
      <form onSubmit={handleConfirm}>
        <DialogContent>
          <Stack spacing={2}>
            <TextField
              defaultValue="https://"
              label="Target URL"
              inputProps={register('target', { required: true })}
            />
            {errors.file ? (
              <Alert severity="error">
                <AlertTitle>The selected actions file contains an error</AlertTitle>
                {errors.file.message}
              </Alert>
            ) : null}

            <TextField
              defaultValue="Default"
              label="Name"
              inputProps={register('name', { required: true })}
            />

            <Button component="label" startIcon={<UploadFile />} variant="contained">
              Add actions
              <input type="file" {...register('file')} hidden />
            </Button>

            <Typography variant="subtitle1">
              <b>Important!</b> Actions are <i>playwright</i> scripts recorded by the <i>@analyzer/console</i>{' '}
              tool. Make sure you use the tool to create them otherwise the snapshot might fail.
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Create</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
