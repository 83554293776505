import { Project } from '@analyzer/client';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

interface DeleteProjectModalProps {
  project?: Project;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export function DeleteProjectModal({ project, open, onClose, onConfirm }: DeleteProjectModalProps) {
  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  const handleClose = () => {
    onClose();
  };

  if (!project) return null;

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Delete project?</DialogTitle>
      <DialogContent>
        <DialogContentText>Are you sure you want to delete project {project?.name} ?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleConfirm}>Confirm</Button>
      </DialogActions>
    </Dialog>
  );
}
