import { TestIdentifier } from '@analyzer/client';
import DataObjectIcon from '@mui/icons-material/DataObject';
import {
  Alert,
  Avatar,
  CircularProgress,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText
} from '@mui/material';
import { Link } from 'react-router-dom';
import { snapshotListQuery } from '../../api/snapshot';
import { PaginatedList } from '../generic/PaginatedList';
import { decodeObjectId } from '../utils';

function SnapshotListElement({ data }: { data: any }) {
  return (
    <ListItem disablePadding>
      <ListItemButton component={Link} to={`/snapshot/${data.id}`}>
        <ListItemAvatar>
          <Avatar>
            <DataObjectIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={data.id} secondary={`Created ${data.date}`} />
      </ListItemButton>
    </ListItem>
  );
}

export function SnapshotsList({ testId }: { testId: TestIdentifier }) {
  const query = snapshotListQuery(testId);
  if (query.isLoading) return <CircularProgress />;
  if (query.isError) return <Alert severity="error">{`Error: ${query.error.message}`}</Alert>;

  return <PaginatedList data={query.data.map(decodeObjectId)} ListElement={SnapshotListElement} />;
}
