import { useParams } from 'react-router-dom';
import { testReadQuery } from '../api/test';
import { Alert, Box, CircularProgress, Tab, Typography } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { NewSnapshotComponent } from '../components/snapshot/new';
import { NewAnalysisComponent } from '../components/analysis/new';
import { DestroyProjectComponent } from '../components/project/destroy';
import { useState } from 'react';
import { SnapshotsList } from '../components/snapshot/list';
import { AnalysisList } from '../components/analysis/list';
import { ReportsList } from '../components/report/list';

type TestViewParams = {
  testId: string;
};

export function TestView() {
  const { testId } = useParams<TestViewParams>();
  if (!testId) throw new Error('Missing parameter');

  const [value, setValue] = useState(0);
  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  const test = testReadQuery(testId);
  if (test.isLoading) return <CircularProgress />;
  if (test.isError) return <Alert severity="error">{`Error: ${test.error.message}`}</Alert>;

  return (
    <Box>
      <TabContext value={value.toString()}>
        <TabList value={value} onChange={handleChange}>
          <Tab label="Test" value="0" />
          <Tab label="Snapshots" value="1" />
          <Tab label="Analyses" value="2" />
          <Tab label="Reports" value="3" />
        </TabList>

        <TabPanel value="0">
          <Box sx={{ display: 'flex' }}>
            <NewSnapshotComponent testId={testId} />
            <NewAnalysisComponent testId={testId} />
            <DestroyProjectComponent testId={testId} />
          </Box>

          <Typography variant="h5">Test Details</Typography>
          <Typography>Name</Typography>
          <p>{test.data.name}</p>
          <Typography>Description</Typography>
          <p>{test.data.target}</p>
          <Typography>Actions</Typography>
          <pre>{test.data.actions ?? 'default'}</pre>
          <Typography>Scripts</Typography>
          <pre>{test.data.initScript ?? 'none'}</pre>
        </TabPanel>

        <TabPanel value="1">
          <SnapshotsList testId={testId} />
        </TabPanel>

        <TabPanel value="2">
          <AnalysisList testId={testId} />
        </TabPanel>

        <TabPanel value="3">
          <ReportsList testId={testId} />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
