import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

export function decodeObjectId(objectId: string) {
  const timestampHexString = objectId.substring(0, 8);
  const timestamp = parseInt(timestampHexString, 16);
  return { id: objectId, date: dayjs(new Date(timestamp * 1000)).fromNow() };
}

export function getRelativeTimeFromTimestamp(timestamp: number) {
  return dayjs(timestamp).fromNow();
}

export function getFormattedDateFromTimestamp(timestamp: number) {
  return dayjs(timestamp).format('YYYY/MM/DD');
}

export function getFormattedDateFromIsoString(isoString: string) {
  return dayjs(isoString).fromNow();
}
