import { CertificateData } from '@analyzer/core/lib/collector/collectors/certificate.js';
import { DNSData } from '@analyzer/core/lib/collector/collectors/dns.js';
import {
  AppConsoleMessage,
  EthereumTransaction,
  WalletInteraction,
  WebRequest,
  WebSocketRequest
} from '@analyzer/core/lib/collector/collectors/web.js';
import { WhoisData } from '@analyzer/core/lib/collector/collectors/whois.js';
import { LiquityEvent } from '@analyzer/core/lib/collector/plugins/liquity/index.js';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Alert,
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import * as x509 from '@peculiar/x509';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { snapshotReadQuery } from '../api/snapshot';
import { getFormattedDateFromTimestamp, getRelativeTimeFromTimestamp } from '../components/utils.js';
import SummarizedLink from '../components/url.js';

function getFilenameFromUrl(url: string, defaultFilename = 'default.txt') {
  const { pathname } = new URL(url);
  const lastSegment = pathname.split('/').pop();
  return lastSegment && lastSegment.length ? lastSegment : defaultFilename;
}

function handleDownload(blobData: BlobPart, filename: string, contentType = 'application/octet-stream') {
  const blob = new Blob([blobData], { type: contentType });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
}

function WebRequestView({ webRequests }: { webRequests: WebRequest[] }) {
  const downloadWebRequest = (webRequest: WebRequest) => {
    const { request, response } = webRequest;
    const filename = getFilenameFromUrl(request.url);
    const content = atob(response.body);
    handleDownload(content, filename);
  };

  const sortedWebRequests = webRequests.sort((a, b) => a.request.url.localeCompare(b.request.url));

  return (
    <Box>
      <Typography variant="h6">HTTP Requests</Typography>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>URL</TableCell>
              <TableCell>SHA</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Size</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedWebRequests.map((webRequest, index) => (
              <TableRow key={index}>
                <TableCell>
                  <SummarizedLink url={webRequest.request.url} />
                </TableCell>
                <TableCell>{webRequest.response.sha256}</TableCell>
                <TableCell>{webRequest.response.contentType}</TableCell>
                <TableCell>{webRequest.response.body.length}</TableCell>
                <TableCell>{webRequest.response.status}</TableCell>
                <TableCell>
                  <Button variant="contained" size="small" onClick={() => downloadWebRequest(webRequest)}>
                    Download
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

function WebSocketView({ sockets }: { sockets: WebSocketRequest[] }) {
  return (
    <Box>
      <Typography variant="h6">Web Socket Requests</Typography>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>URL</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sockets.map((socket, index) => (
              <TableRow key={index}>
                <TableCell>{socket.id}</TableCell>
                <TableCell>
                  <SummarizedLink url={socket.url} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

function EthereumAddress({ address }: { address: string }) {
  return <SummarizedLink url={`https://etherscan.io/address/${address}`} title={address} />;
}

function EthereumBlock({ block }: { block: string }) {
  const n = parseInt(block, 16);
  return <SummarizedLink url={`https://etherscan.io/block/${n}`} title={n.toString()} />;
}

function EthereumChain({ chainId }: { chainId: string }) {
  switch (parseInt(chainId, 16)) {
    case 1:
      return <>Mainnet</>;
    case 3:
      return <>Ropsten</>;
    case 4:
      return <>Rinkeby</>;
    case 42:
      return <>Kovan</>;
    case 5:
      return <>Görli</>;
    case 100:
      return <>xDai</>;
    default:
      return <>Unknown</>;
  }
}

function EthereumMethod({ method }: { method: string }) {
  if (method === 'eth_requestAccounts') return <>eth_requestAccounts</>;

  return (
    <SummarizedLink
      url={`https://docs.infura.io/infura/networks/ethereum/json-rpc-methods/${method.toLowerCase()}`}
      title={method}
    />
  );
}

function EthereumTransactionResponse({ transaction }: { transaction: EthereumTransaction }) {
  switch (transaction.method) {
    case 'eth_accounts':
    case 'eth_requestAccounts':
      return transaction.response.map((address: string) => <EthereumAddress address={address} />);

    case 'eth_blockNumber':
      return <EthereumBlock block={transaction.response} />;

    case 'eth_chainId':
      return <EthereumChain chainId={transaction.response} />;

    default:
      return <pre>{JSON.stringify(transaction.response)}</pre>;
  }
}

function EthereumTransactionView({ transactions }: { transactions: EthereumTransaction[] }) {
  return (
    <Box>
      <Typography variant="h6">Ethereum RPC Requests</Typography>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Method</TableCell>
              <TableCell>Params</TableCell>
              <TableCell>Response</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions.map((tx, index) => (
              <TableRow key={index}>
                <TableCell>
                  <EthereumMethod method={tx.method} />
                </TableCell>
                <TableCell>
                  <pre>{JSON.stringify(tx.params, null, 2)}</pre>
                </TableCell>
                <TableCell>
                  <EthereumTransactionResponse transaction={tx} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

function ConsoleMessageView({ messages }: { messages: AppConsoleMessage[] }) {
  return (
    <Box>
      <Typography variant="h6">Console Messages</Typography>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Message</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {messages.map((message, index) => (
              <TableRow key={index}>
                <TableCell>{message.type}</TableCell>
                <TableCell>
                  <SummarizedLink url={message.location} />
                </TableCell>
                <TableCell>
                  <pre>{message.text}</pre>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

function DNSView({ dnsData }: { dnsData: DNSData[] }) {
  return (
    <Box>
      <Typography variant="h6">Whois Data</Typography>
      {dnsData.map((dnsData, index) => (
        <Paper sx={{ p: 2, mt: 2 }} key={index}>
          <Typography variant="subtitle1">Hostname: {dnsData.hostname}</Typography>
          <List dense>
            {dnsData.records.map((record, i) => (
              <ListItem key={i}>
                <ListItemText primary={`${record.type}: ${record.entry}`} />
              </ListItem>
            ))}
          </List>
        </Paper>
      ))}
    </Box>
  );
}

function LiquityView({ liquityEvents }: { liquityEvents: LiquityEvent[] }) {
  const [liquity] = liquityEvents;
  if (!liquity)
    return (
      <>
        <Box>
          <Typography variant="h6">No Liquity Events</Typography>
        </Box>
      </>
    );

  return (
    <Box>
      <Typography variant="h6">Liquity Events</Typography>
      <Paper sx={{ mt: 2 }}>
        <List dense={true}>
          <ListSubheader>Metadata</ListSubheader>
          <ListItem>
            <ListItemText primary="Chain ID" secondary={liquity.chainId} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Deployment Date" secondary={liquity.deploymentDate.toString()} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Frontend Tag" secondary={liquity.frontendTag} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Start Block" secondary={liquity.startBlock} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Version" secondary={liquity.version} />
          </ListItem>
          <ListSubheader>Addresses</ListSubheader>
          {Object.entries(liquity.addresses).map(([key, value]) => (
            <ListItem key={key}>
              <ListItemText primary={`Address for '${key}'`} secondary={value} />
            </ListItem>
          ))}
        </List>
      </Paper>
    </Box>
  );
}

function WalletInteractionView({ interactions }: { interactions: WalletInteraction[] }) {
  return (
    <Box>
      <Typography variant="h6">Wallet Interactions</Typography>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Method</TableCell>
              <TableCell>Params</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {interactions.map((interaction, index) => (
              <TableRow key={index}>
                <TableCell>{interaction.method}</TableCell>
                <TableCell>
                  <pre>{JSON.stringify(interaction.params)}</pre>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

function WhoisDataView({ data }: { data: WhoisData[] }) {
  return (
    <Box>
      <Typography variant="h6">Whois Data</Typography>
      {data.map((whoisData, index) => (
        <Paper sx={{ p: 2, mt: 2 }} key={index}>
          <Typography variant="subtitle1">Address: {whoisData.address}</Typography>
          <List dense>
            {whoisData.records.map((record, i) => (
              <ListItem key={i}>
                <ListItemText primary={`${record.type}: ${record.entry}`} />
              </ListItem>
            ))}
          </List>
        </Paper>
      ))}
    </Box>
  );
}

function CertificateDataView({ certificates }: { certificates: CertificateData[] }) {
  function asPEM(base64String: string) {
    const beginCert = '-----BEGIN CERTIFICATE-----\n';
    const endCert = '\n-----END CERTIFICATE-----';
    const formattedBase64 = base64String.match(/.{1,64}/g)!.join('\n');
    return beginCert + formattedBase64 + endCert;
  }
  const downloadCertificateData = (data: CertificateData) => {
    const { certificate, hostname } = data;
    handleDownload(asPEM(certificate), `${hostname}.pem`);
  };

  return (
    <Box>
      <Typography variant="h6">Certificate</Typography>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Hostname</TableCell>
              <TableCell>Serial Number</TableCell>
              <TableCell>Subject</TableCell>
              <TableCell>Issuer</TableCell>
              <TableCell>Signature Algorithm</TableCell>
              <TableCell>Valid From</TableCell>
              <TableCell>Valid Until</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {certificates.map((certificate, index) => {
              const cert = new x509.X509Certificate(certificate.certificate);
              return (
                <TableRow key={index}>
                  <TableCell>{certificate.hostname}</TableCell>
                  <TableCell>{cert.serialNumber}</TableCell>
                  <TableCell>{cert.subject}</TableCell>
                  <TableCell>{cert.issuer}</TableCell>
                  <TableCell>
                    {cert.signatureAlgorithm.name} {cert.signatureAlgorithm.hash.name}
                  </TableCell>
                  <TableCell>{cert.notBefore.toDateString()}</TableCell>
                  <TableCell>{cert.notAfter.toDateString()}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => downloadCertificateData(certificate)}
                    >
                      Download
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

function ScreenshotView({ data }: { data: string }) {
  return (
    <Box>
      <Typography variant="h6">Screenshot</Typography>
      <img src={`data:image/png;base64,${data}`} alt="screenshot" />
    </Box>
  );
}

export function SnapshotView() {
  const [value, setValue] = useState('1');
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const { snapshotId } = useParams();
  if (!snapshotId) throw new Error('Missing parameter');

  const snapshot = snapshotReadQuery(snapshotId);
  if (snapshot.isLoading) return <CircularProgress />;
  if (snapshot.isError) return <Alert severity="error">{`Error: ${snapshot.error.message}`}</Alert>;

  function chip(name: string, content: number) {
    const contentString = content > 99 ? 'n' : content.toString();
    return <Chip size="small" label={name} avatar={<Avatar>{contentString}</Avatar>} />;
  }

  function handleDownloadHarClick(filename: string, data: any, type: string) {
    const blob = new Blob([data], { type });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function renderBaseSnapshotDetails(base: string | undefined) {
    if (!base) return undefined;
    return (
      <Typography>
        Base:
        <Link to={`/snapshot/${base}`}>{base}</Link>
      </Typography>
    );
  }

  return (
    <Box>
      <Typography>{`Kind: ${snapshot.data.kind ?? 'full'}`}</Typography>

      {renderBaseSnapshotDetails(snapshot.data.base)}

      <Typography>
        Test:
        <Link to={`/test/${snapshot.data.test}`}>{snapshot.data.test}</Link>
      </Typography>

      <Typography>{`Created: ${getRelativeTimeFromTimestamp(
        snapshot.data.timestamp
      )} on ${getFormattedDateFromTimestamp(snapshot.data.timestamp)}`}</Typography>

      <Typography sx={{ color: snapshot.data.error ? 'error.main' : 'success.main' }}>
        Status: {snapshot.data.error ? `Error: ${snapshot.data.error}` : 'Success'}
      </Typography>

      <Button
        onClick={async () => {
          const rawData = atob(snapshot.data.harData);
          handleDownloadHarClick(`${snapshotId}-trace.har`, rawData, 'application/json');
        }}
      >
        Download HAR File
      </Button>

      <TabContext value={value}>
        <TabList onChange={handleChange}>
          <Tab label={chip('Requests', snapshot.data.web.requests.length)} value="1" />
          <Tab label={chip('Sockets', snapshot.data.web.sockets.length)} value="2" />
          <Tab label={chip('Transactions', snapshot.data.web.transactions.length)} value="3" />
          <Tab label={chip('Wallet', snapshot.data.web.wallet.length)} value="4" />
          <Tab label={chip('Console', snapshot.data.web.console.length)} value="5" />
          <Tab label={chip('Liquity', snapshot.data.web.data.length)} value="6" />
          <Tab label={chip('DNS', snapshot.data.dns.length)} value="7" />
          <Tab label={chip('WHOIS', snapshot.data.whois.length)} value="8" />
          <Tab label={chip('Certificates', snapshot.data.certificates.length)} value="9" />
          <Tab label={chip('Screenshots', 1)} value="10" />
        </TabList>
        <TabPanel value="1">
          <WebRequestView webRequests={snapshot.data.web.requests} />
        </TabPanel>
        <TabPanel value="2">
          <WebSocketView sockets={snapshot.data.web.sockets} />
        </TabPanel>
        <TabPanel value="3">
          <EthereumTransactionView transactions={snapshot.data.web.transactions} />
        </TabPanel>
        <TabPanel value="4">
          <WalletInteractionView interactions={snapshot.data.web.wallet} />
        </TabPanel>
        <TabPanel value="5">
          <ConsoleMessageView messages={snapshot.data.web.console} />
        </TabPanel>
        <TabPanel value="6">
          <LiquityView liquityEvents={snapshot.data.web.data} />
        </TabPanel>
        <TabPanel value="7">
          <DNSView dnsData={snapshot.data.dns} />
        </TabPanel>
        <TabPanel value="8">
          <WhoisDataView data={snapshot.data.whois} />
        </TabPanel>
        <TabPanel value="9">
          <CertificateDataView certificates={snapshot.data.certificates} />
        </TabPanel>
        <TabPanel value="10">
          <ScreenshotView data={snapshot.data.screenshotData} />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
