import { Button } from '@mui/material';
import { snapshotCreateMutation } from '../../api/snapshot';
import { TestIdentifier } from '@analyzer/client';

export function NewSnapshotComponent({ testId }: { testId: TestIdentifier }) {
  const { isLoading, mutate } = snapshotCreateMutation();

  function handler() {
    mutate({ testId });
  }

  return (
    <Button disabled={isLoading} aria-busy={isLoading} onClick={handler}>
      Snapshot
    </Button>
  );
}
