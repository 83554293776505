import { Alert, AlertSeverity } from '@analyzer/core/lib/analyzer/plugins/index.js';
import { Paper, Theme, Tooltip, Typography, alpha } from '@mui/material';
import { Link } from 'react-router-dom';
import { ContentChangeAlertComponent } from './content-change.js';
import { renderEthereumRPCChangeAlert } from './ethereum-rpc-change.js';

function alertTypeToTitle(type: string) {
  let title;
  switch (type) {
    case 'dependency-change':
      title = 'Dependency Change';
      break;
    case 'additional-request':
      title = 'Additional Request';
      break;
    case 'certificate-change':
      title = 'Certificate Change';
      break;
    case 'certificate-security':
      title = 'Certificate Security';
      break;
    case 'content-change':
      title = 'Content Change';
      break;
    case 'content-security-policy':
      title = 'Content Security Policy';
      break;
    case 'content-type-options-alert':
      title = 'Content Type Options';
      break;
    case 'cors-policy':
      title = 'CORS Policy';
      break;
    case 'dns-record-change':
      title = 'DNS Record Change';
      break;
    case 'ethereum-contract-change':
      title = 'Ethereum Contract Change';
      break;
    case 'resource-integrity':
      title = 'Resource Integrity';
      break;
    case 'ethereum-rpc-change':
      title = 'Ethereum RPC Call Change';
      break;
    case 'google-safe-browsing':
      title = 'Malicious URL';
      break;
    case 'insecure-connection':
      title = 'Insecure Connection';
      break;
    case 'insecure-cookie':
      title = 'Insecure Cookie';
      break;
    case 'liquity-change':
      title = 'Liquity Frontend Change';
      break;
    case 'no-referrer':
      title = 'Referrer Policy Alert';
      break;
    case 'no-x-frame-options':
      title = 'X-Frame-Options Alert';
      break;
    case 'security-header-change':
      title = 'Security Header Change';
      break;
    case 'server-change':
      title = 'Server Endpoint Change';
      break;
    case 'strict-transport-security':
      title = 'Transport Security Alert';
      break;
    case 'tls-connection-alert':
      title = 'TLS Connection Alert';
      break;
    case 'whois-change':
      title = 'WHOIS Change';
      break;
    case 'score-change':
      title = 'Security Score Change';
      break;
  }

  return title;
}

const renderAlertSpecifics = (alert: Alert) => {
  switch (alert.type) {
    case 'dependency-change':
      return (
        <>
          <Typography variant="subtitle2">Description</Typography>
          <Typography variant="body2">
            This alert is triggered when the website has new requests to external resources.
          </Typography>

          <Typography variant="subtitle2">Impact</Typography>
          <Typography variant="body2">
            The addition of new dependencies could indicate changes in the website's behavior or potential
            security risks. These new dependencies should be reviewed to ensure they are expected and don't
            introduce any vulnerabilities or malicious behavior.
          </Typography>

          <Typography variant="subtitle2">Details</Typography>
          <Typography variant="body2">The following new origins were detected:</Typography>
          <ul>
            {alert.origins.map((origin, index) => (
              <li key={index}>{origin}</li>
            ))}
          </ul>
        </>
      );
    case 'ethereum-provider-change':
      return (
        <>
          <Typography variant="subtitle2">Description</Typography>
          <Typography variant="body2">
            This alert is triggered when new RPC providers are detected in the 'wallet_addEthereumChain'
            method calls made by the DApp that were not present in previous snapshots.
          </Typography>

          <Typography variant="subtitle2">Impact</Typography>
          <Typography variant="body2">
            The addition of new RPC providers could indicate changes in the DApp's communication behavior or
            potential security risks. These new providers should be reviewed to ensure they are reliable and
            do not pose any vulnerabilities or malicious behavior.
          </Typography>

          <Typography variant="subtitle2">Details</Typography>
          <Typography variant="body2">The following new RPC providers have been added:</Typography>
          <ul>
            {alert.rpcUrls.map((url, index) => (
              <li key={index}>
                <strong>RPC Provider URL:</strong> {url}
              </li>
            ))}
          </ul>
        </>
      );

    case 'ethereum-contract-change':
      return (
        <>
          <Typography variant="subtitle2">Description</Typography>
          <Typography variant="body2">
            This alert is triggered when new contracts are detected being called by the DApp that were not
            present in previous snapshots.
          </Typography>

          <Typography variant="subtitle2">Impact</Typography>
          <Typography variant="body2">
            Interactions with new contracts could indicate changes in the DApp's behavior or potential
            security risks. These new contracts should be reviewed to ensure they do not introduce any
            vulnerabilities or malicious behavior.
          </Typography>

          <Typography variant="subtitle2">Details</Typography>
          <Typography variant="body2">The following new contracts have been called:</Typography>
          <ul>
            {alert.contracts.map((contract, index) => (
              <li key={index}>
                <strong>Contract Address:</strong> {contract}
              </li>
            ))}
          </ul>
        </>
      );

    case 'google-safe-browsing':
      return (
        <>
          <Typography variant="subtitle2">Description</Typography>
          <Typography variant="body2">
            This alert is triggered when URLs used by the DApp are found in Google's Safe Browsing database.
            These URLs may be associated with known threats, such as phishing or malware distribution.
          </Typography>

          <Typography variant="subtitle2">Impact</Typography>
          <Typography variant="body2">
            The presence of these URLs in your DApp could lead to significant security risks, including
            exposure to phishing, malware, or other malicious activities. It is critical to investigate and
            remediate these issues promptly.
          </Typography>

          <Typography variant="subtitle2">Details</Typography>
          <Typography variant="body2">The following URL(s) were flagged:</Typography>
          <ul>
            {alert.results.map((result, index) => (
              <li key={index}>
                <Typography variant="body2">
                  <strong>URL:</strong> {result.url}
                </Typography>
                <Typography variant="body2">
                  <strong>Threat Type:</strong> {result.threatType || 'Unknown'}
                </Typography>
                <Typography variant="body2">
                  <strong>Platform Type:</strong> {result.platformType || 'Unknown'}
                </Typography>
                <Typography variant="body2">
                  <strong>Threat Entry Type:</strong> {result.threatEntryType || 'Unknown'}
                </Typography>
              </li>
            ))}
          </ul>
        </>
      );

    case 'ethereum-rpc-change':
      return renderEthereumRPCChangeAlert(alert);

    case 'content-security-policy':
      return (
        <>
          <Typography variant="subtitle2">Description</Typography>
          <Typography variant="body2">
            This alert is triggered when issues are detected in the Content Security Policies (CSP). These
            issues could be a missing CSP, report-only CSP, overly permissive CSP, unsafe use of CSP, missing
            'report-to' directive, or missing fallback directives.
          </Typography>

          <Typography variant="subtitle2">Impact</Typography>
          <Typography variant="body2">
            An incorrectly configured CSP can expose the website to Cross-Site Scripting (XSS) attacks and
            data injection attacks. It's crucial to have a correctly set CSP to protect your site and its
            users.
          </Typography>

          <Typography variant="subtitle2">Details</Typography>
          <Typography variant="body2">The following issues were detected:</Typography>
          <Typography variant="body2">
            Kind of issue: <strong>{alert.kind}</strong>
          </Typography>
          <Typography variant="body2">
            Location of issue: <strong>{alert.location}</strong>
          </Typography>
          {alert.policies.map((policy, idx) => (
            <div key={idx}>
              <Typography variant="body2">
                Policy at URL: <strong>{policy.url}</strong>
              </Typography>
              <Typography variant="body2">
                Report only: <strong>{policy.reportOnly ? 'Yes' : 'No'}</strong>
              </Typography>
              <ul>
                {policy.directives.map((directive, index) => (
                  <li key={index}>
                    <strong>{directive.name}:</strong> {directive.values.join(', ')}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </>
      );

    case 'liquity-change':
      return (
        <>
          <Typography variant="subtitle2">Description</Typography>
          <Typography variant="body2">
            This alert is triggered when changes in Liquity's frontend metadata are detected. This includes
            changes in the chain ID, deployment date, frontend tag, contract version, or contract addresses.
          </Typography>

          <Typography variant="subtitle2">Impact</Typography>
          <Typography variant="body2">
            Changes in Liquity's metadata could impact the functionality of the Liquity protocol, potentially
            affecting users' interactions with it. It's important to review these changes to ensure they are
            expected and don't introduce any security risks.
          </Typography>

          <Typography variant="subtitle2">Details</Typography>
          <ul>
            {alert.changes.map((change, idx) => (
              <li key={idx}>{change}</li>
            ))}
          </ul>
        </>
      );

    case 'security-header-change':
      return (
        <>
          <Typography variant="subtitle2">Description</Typography>
          <Typography variant="body2">
            This alert is triggered when security headers have been removed from the HTTP responses of the
            following origin: <strong>{alert.origin}</strong>.
          </Typography>

          <Typography variant="subtitle2">Impact</Typography>
          <Typography variant="body2">
            Security headers are crucial for securing your web service. Their removal can expose the
            application to various security risks, such as cross-site scripting (XSS) attacks, clickjacking,
            and other code injection attacks.
          </Typography>

          <Typography variant="subtitle2">Details</Typography>
          <Typography variant="body2">The following headers were removed:</Typography>
          <ul>
            {alert.headers.map((header) => (
              <li key={header}>
                <strong>{header}</strong>
              </li>
            ))}
          </ul>
        </>
      );

    case 'resource-integrity':
      return (
        <>
          <Typography variant="subtitle2">Description</Typography>
          <Typography variant="body2">
            Subresource Integrity (SRI) is a security feature that enables browsers to verify that resources
            they fetch (such as images, scripts, or stylesheets) are delivered without unexpected
            manipulation. It works by allowing you to provide a cryptographic hash that a fetched resource
            must match.
          </Typography>

          <Typography variant="subtitle2">Impact</Typography>
          <Typography variant="body2">
            Not using subresource integrity for externally loaded resources can pose serious security risks.
            If the resources are unexpectedly manipulated (for instance, they are compromised at the source),
            they can lead to injection of malicious content, potentially leading to Cross-Site Scripting (XSS)
            attacks or data breaches.
          </Typography>

          <Typography variant="subtitle2">Details</Typography>
          <Typography variant="body2">
            The following external resources were loaded without subresource integrity:
          </Typography>
          <ul>
            {alert.resources.map((resource, idx) => (
              <li key={idx}>{resource.resource}</li>
            ))}
          </ul>
        </>
      );

    case 'additional-request':
      return (
        <>
          <Typography variant="subtitle2">Description</Typography>
          <Typography variant="body2">
            An Additional Request Alert is triggered when one or more requests are detected that have not been
            seen before. These additional requests can potentially reveal connections to compromised hosts,
            indicating potential security risks. It is important to investigate and analyze such requests to
            ensure the integrity and security of the system.
          </Typography>
          <Typography variant="subtitle2">Impact</Typography>
          <Typography variant="body2">
            The impact of malicious requests can have significant consequences. One potential impact is the
            exfiltration of private data to an attacker-controlled host when a compromised application is
            involved.
          </Typography>
          <Typography variant="subtitle2">Details</Typography>
          {alert.requests.map((request, index) => (
            <Typography variant="body2" key={index}>
              <Tooltip title={<>{request.response.contentType}</>}>
                <Link to={request.request.url}>{request.request.url}</Link>
              </Tooltip>
            </Typography>
          ))}
        </>
      );

    case 'insecure-connection':
      return (
        <>
          <Typography variant="subtitle2">Description</Typography>
          <Typography variant="body2">
            An Insecure Connection Alert is triggered when connections that do not use secure protocols (like
            HTTPS) are detected. Secure connections are important as they help keep information transferred
            between your website and its users private and secure.
          </Typography>

          <Typography variant="subtitle2">Impact</Typography>
          <Typography variant="body2">
            Insecure connections can expose sensitive information transferred between your website and its
            users to potential interception by attackers. This could compromise user data and harm your site's
            reputation.
          </Typography>

          <Typography variant="subtitle2">Details</Typography>
          <Typography variant="body2">The following insecure connections were detected:</Typography>
          {alert.connections.map((connection, index) => (
            <div key={index}>
              <Typography variant="body2">Connection Address: {connection.address}</Typography>
              <Typography variant="body2">Whois Address: {connection.whois.address}</Typography>
              {connection.whois.records.map((record, recordIndex) => (
                <div key={recordIndex}>
                  <Typography variant="body2">Record Type: {record.type}</Typography>
                  <Typography variant="body2">Entry: {record.entry}</Typography>
                </div>
              ))}
            </div>
          ))}
        </>
      );

    case 'whois-change':
      return (
        <>
          <Typography variant="subtitle2">Description</Typography>
          <Typography variant="body2">
            A WHOIS Change Alert is raised when there are significant modifications detected in the WHOIS
            records for your domain. WHOIS records contain important information about the owner, location,
            and other related details of a domain. Changes may occur in Autonomous System (AS) details,
            netblock assignments, or netname records.
          </Typography>

          <Typography variant="subtitle2">Impact</Typography>
          <Typography variant="body2">
            Unauthorized or unexpected changes in WHOIS records may indicate malicious activity such as domain
            hijacking. This could potentially disrupt your website's operation, compromise your website's
            reputation, or lead to loss of domain control.
          </Typography>

          <Typography variant="subtitle2">Details</Typography>
          <Typography variant="body2">The following changes were detected in the WHOIS records:</Typography>
          <ul>
            {alert.reasons.map((reason, idx) => (
              <li key={idx}>{reason}</li>
            ))}
          </ul>
        </>
      );

    case 'certificate-change':
      return (
        <>
          <Typography variant="subtitle2">Description</Typography>
          <Typography variant="body2">
            A Certificate Change Alert is generated when there is a change in the TLS certificate associated
            with a given resource. Certificates commonly change when the previous certificate expires.
            However, in certain cases, a certificate might be changed due to nefarious actions such as a
            server hijack, during which an attacker could potentially issue a different certificate.
          </Typography>

          <Typography variant="subtitle2">Impact</Typography>
          <Typography variant="body2">
            A changed certificate, under malicious circumstances, could indicate that communications with the
            target server are under the control of an attacker. This could lead to the compromise of sensitive
            data, a breach in privacy, or other potential security risks.
          </Typography>

          <Typography variant="subtitle2">Details</Typography>
          <Typography variant="body2">The certificate for {alert.certificate.hostname} changed:</Typography>

          {alert.change.type === 'issuer' && (
            <>
              <div>
                <Typography variant="body2">New Issuer:</Typography>
                <Typography variant="body2">{alert.change.issuer}</Typography>
              </div>
              <div>
                <Typography variant="body2">Historical Certificate Issuers: </Typography>
                <ul>
                  {alert.change.issuers.map((issuer, idx) => (
                    <li key={idx}>{issuer}</li>
                  ))}
                </ul>
              </div>
            </>
          )}

          {alert.change.type === 'renewal' && (
            <div>
              <Typography variant="body2">Expiration dates:</Typography>
              <ul>
                {alert.change.expirations.map((expiration, idx) => (
                  <li key={idx}>Certificate expires in {expiration} day(s)</li>
                ))}
              </ul>
            </div>
          )}
        </>
      );

    case 'certificate-security':
      return (
        <>
          <Typography variant="subtitle2">Description</Typography>
          <Typography variant="body2">
            A Certificate Security Alert is raised when potential security issues are identified in a TLS
            certificate. This can occur due to various reasons such as an expired certificate, a weak
            encryption algorithm, or an unrecognized Certificate Authority (CA).
          </Typography>

          <Typography variant="subtitle2">Impact</Typography>
          <Typography variant="body2">
            Insecure certificates can lead to unencrypted communication, which can make transmitted data
            vulnerable to interception or manipulation. This could potentially result in unauthorized access
            to sensitive data, loss of data integrity, or exposure of user information.
          </Typography>

          <Typography variant="subtitle2">Details</Typography>
          <Typography variant="body2">
            The certificate for {alert.location} has the following security issues:
          </Typography>
          <ul>
            {alert.reasons.map((reason, idx) => (
              <li key={idx}>{reason}</li>
            ))}
          </ul>
        </>
      );

    case 'status-change':
      return (
        <>
          <Typography variant="subtitle2">Description</Typography>
          <Typography variant="body2">
            A Status Change Alert is issued when the application transitions to an unreachable state. This
            could indicate network issues, server downtime, or unexpected errors within the application.
          </Typography>

          <Typography variant="subtitle2">Impact</Typography>
          <Typography variant="body2">
            When the application becomes unreachable, it may significantly impact user experience, lead to
            data loss, or interrupt critical workflows. Prompt investigation and resolution are crucial to
            minimize potential disruptions.
          </Typography>

          <Typography variant="subtitle2">Details</Typography>
          <Typography variant="body2">The application became unreachable at approximately:</Typography>
          <Typography variant="body2">
            <strong>{new Date(alert.timestamp).toLocaleString()}</strong>
          </Typography>
        </>
      );

    case 'content-change':
      return ContentChangeAlertComponent(alert);

    case 'cors-policy':
      return (
        <>
          <Typography variant="subtitle2">Description</Typography>
          <Typography variant="body2">
            Cross-Origin Resource Sharing (CORS) is a security feature that allows resources on a webpage
            (such as fonts and JavaScript) to be requested from a domain different than the one from which the
            resource originated.
          </Typography>

          <Typography variant="subtitle2">Impact</Typography>
          <Typography variant="body2">
            Incorrect implementation of CORS policy can potentially lead to security vulnerabilities. These
            include exposure of sensitive user data and violation of the same-origin policy, which could
            enable cross-site request forgery attacks or data breaches.
          </Typography>

          <Typography variant="subtitle2">Details</Typography>
          <Typography variant="body2">Requests with overly permissive CORS policies:</Typography>
          <ul>
            {alert.requests.map((request, idx) => (
              <li key={idx}>{request}</li>
            ))}
          </ul>
        </>
      );

    case 'dns-record-change':
      return (
        <>
          <Typography variant="subtitle2">Description</Typography>
          <Typography variant="body2">
            A DNS Record Change Alert is triggered when changes are detected in the DNS records of your
            domain. DNS records guide network traffic and can significantly impact your website's
            accessibility and security.
          </Typography>

          <Typography variant="subtitle2">Impact</Typography>
          <Typography variant="body2">
            Changes in DNS records can alter where your domain's traffic is directed. If unauthorized changes
            occur, this could potentially lead to traffic being directed to malicious websites, result in
            downtime, or expose users to security risks.
          </Typography>

          <Typography variant="subtitle2">Details</Typography>
          <Typography variant="body2">DNS records for {alert.hostname} changed significantly:</Typography>
          <ul>
            {alert.changes.map((change, idx) => (
              <li key={idx}>
                {change.type} - {change.record}
              </li>
            ))}
          </ul>
        </>
      );

    case 'no-referrer':
      return (
        <>
          <Typography variant="subtitle2">Description</Typography>
          <Typography variant="body2">
            The Referrer Policy is a security feature that controls how and when the browser header field
            'Referer' is included with requests made from your site. This can have implications on privacy and
            security.
          </Typography>
          <Typography variant="subtitle2">Impact</Typography>
          <Typography variant="body2">
            Not implementing a Referrer Policy can expose sensitive user data in the Referrer header when
            navigating to other sites or cause the leakage of potentially confidential information about your
            website's structure or URLs. This can be exploited by malicious entities for purposes such as
            phishing attacks, data theft, or unwanted tracking.
          </Typography>
          <Typography variant="subtitle2">Details</Typography>
          <Typography variant="body2">URLs without Referrer Policy:</Typography>
          <ul>
            {alert.urls.map((url, i) => (
              <li key={i}>{url}</li>
            ))}
          </ul>
        </>
      );

    case 'no-x-frame-options':
      return (
        <>
          <Typography variant="subtitle2">Description</Typography>
          <Typography variant="body2">
            The X-Frame-Options HTTP response header can be used to indicate whether or not a browser should
            be allowed to render a page in a frame, iframe, embed or object.
          </Typography>
          <Typography variant="body2">
            The website has made a number of requests without including an 'X-Frame-Options' header.
          </Typography>
          <Typography variant="subtitle2">Impact</Typography>
          <Typography variant="body2">
            The absence of this header means the page could be vulnerable to 'Clickjacking' attacks.
          </Typography>
          <Typography variant="subtitle2">Details</Typography>
          <Typography variant="body2">{alert.description}</Typography>
          <Typography variant="body2">
            The following URL(s) were served without X-FrameOptions header option:
          </Typography>
          <ul>
            {alert.urls.map((url, i) => (
              <li key={i}>{url}</li>
            ))}
          </ul>
        </>
      );

    case 'insecure-cookie':
      return (
        <>
          <Typography variant="subtitle2">Description</Typography>
          <Typography variant="body2">
            Cookies are often used to hold sensitive information and preferences of a user for a specific
            site. When cookies are insecure, this data might be accessible to malicious parties.
          </Typography>

          <Typography variant="subtitle2">Impact</Typography>
          <Typography variant="body2">
            The exposure of insecure cookies can lead to several security risks, such as session hijacking and
            identity theft. By exploiting insecure cookies, an attacker can impersonate a user and perform
            actions on their behalf, potentially leading to unauthorized access to sensitive information.
          </Typography>

          <Typography variant="subtitle2">Details</Typography>
          <Typography variant="body2">Insecure Cookies:</Typography>
          {alert.insecureCookies.map((cookie, index) => (
            <div key={index}>
              <Typography variant="body2">
                Insecure Cookie {cookie.name} from {cookie.origin} has insecure parameters:
              </Typography>
              <Typography variant="body2">Secure: {cookie.secure ? 'Yes' : 'No'}</Typography>
              <Typography variant="body2">Http Only: {cookie.httpOnly ? 'Yes' : 'No'}</Typography>
              <Typography variant="body2">Same Site: {cookie.sameSite ? 'Yes' : 'No'}</Typography>
            </div>
          ))}
        </>
      );

    case 'server-change':
      return (
        <>
          <Typography variant="subtitle2">Description</Typography>
          <Typography variant="body2">
            A Server Change Alert indicates that the server previously serving a specific resource has changed
            and that the resource is now being served from a different endpoint. This new endpoint has not
            been seen before.
          </Typography>

          <Typography variant="subtitle2">Impact</Typography>
          <Typography variant="body2">
            Unusual changes in the server serving a resource can sometimes indicate that there has been a
            malicious takeover.
          </Typography>

          <Typography variant="subtitle2">Details</Typography>
          <Typography variant="body2">Details of the server change are as follows:</Typography>
          <div>From:</div>
          <div>Hostname: {alert.from.hostname}</div>
          <div>Address: {alert.from.address}</div>
          <div>Port: {alert.from.port}</div>
          <div>To:</div>
          <div>Hostname: {alert.to.hostname}</div>
          <div>Address: {alert.to.address}</div>
          <div>Port: {alert.to.port}</div>
        </>
      );

    case 'strict-transport-security':
      return (
        <>
          <Typography variant="subtitle2">Description</Typography>
          <Typography variant="body2">
            HTTP Strict Transport Security (HSTS) is a security feature that ensures all communication from a
            browser to a website is encrypted, providing greater security. When HSTS is not implemented, data
            is not ensured to be securely transported.
          </Typography>

          <Typography variant="subtitle2">Impact</Typography>
          <Typography variant="body2">
            Not implementing HSTS can lead to attacks such as man-in-the-middle (MITM) attacks, where the
            attacker could intercept and manipulate the communication between the user and the website,
            potentially gaining access to sensitive information.
          </Typography>

          <Typography variant="subtitle2">Details</Typography>
          <Typography variant="body2">Requests lacking HTTP Strict Transport Security (HSTS):</Typography>
          <ul>
            {alert.requests.map((request, idx) => (
              <li key={idx}>{request}</li>
            ))}
          </ul>
        </>
      );

    case 'content-type-options-alert':
      return (
        <>
          <Typography variant="subtitle2">Description</Typography>
          <Typography variant="body2">
            The X-Content-Type-Options is a security header that can be set by the server to instruct the
            browser to not change the MIME types specified in the Content-Type headers. When set to 'nosniff',
            it helps to protect the site from certain types of attacks by preventing the browser from trying
            to interpret files as something other than declared by the content type.
          </Typography>

          <Typography variant="subtitle2">Impact</Typography>
          <Typography variant="body2">
            Lack of a 'nosniff' directive can lead to security issues. An attacker might be able to trick a
            user's browser into executing malicious scripts or hijack a web page by causing the browser to
            incorrectly interpret the content type of a resource.
          </Typography>

          <Typography variant="subtitle2">Details</Typography>
          <Typography variant="body2">Requests without 'nosniff' header:</Typography>
          <ul>
            {alert.unsafeRequests.map((url, index) => (
              <li key={index}>{url}</li>
            ))}
          </ul>
        </>
      );

    case 'tls-connection-alert':
      return (
        <>
          <Typography variant="subtitle2">Description</Typography>
          <Typography variant="body2">
            A TLS Connection Alert is triggered when there are insecure connections identified in your
            environment. These insecure connections could be due to the use of insecure ciphers or protocols,
            or lacking Perfect Forward Secrecy (PFS).
          </Typography>

          <Typography variant="subtitle2">Impact</Typography>
          <Typography variant="body2">
            Insecure connections can compromise the data being transmitted between the user and the server,
            making it vulnerable to interception and alteration. This could potentially lead to unauthorized
            access to sensitive data or exposure of user information.
          </Typography>

          <Typography variant="subtitle2">Details</Typography>
          <Typography variant="body2">
            Below are the hostnames that have been identified with insecure connections. Here are the
            identified issues for each host:
          </Typography>
          {alert.insecureConnections.map((connection, index) => (
            <div key={index}>
              <Typography variant="body2">{connection.hostname}</Typography>
              <ul>
                {connection.issues.map((issue, i) => (
                  <li key={i}>{issue}</li>
                ))}
              </ul>
            </div>
          ))}
        </>
      );

    case 'score-change':
      return (
        <>
          <Typography variant="subtitle2">Description</Typography>
          <Typography variant="body2">
            A Score Change Alert is triggered when the current score deviates significantly from its
            historical average.
          </Typography>

          <Typography variant="subtitle2">Impact</Typography>
          <Typography variant="body2">
            A significant change in score may indicate a notable event or trend. The implications of this
            change would depend on the context of the score.
          </Typography>

          <Typography variant="subtitle2">Details</Typography>
          <Typography variant="body2">
            The current score is {alert.currentScore}, whereas the historical average is
            {alert.historicalMean} with a standard deviation of {alert.historicalStdDev}.
          </Typography>

          <Typography variant="body2">
            {Math.abs(alert.currentScore - alert.historicalMean) > alert.historicalStdDev
              ? 'The change in score is significant, as it is greater than one standard deviation from the mean.'
              : 'The change in score is within one standard deviation of the mean, so it may not be significant.'}
          </Typography>
        </>
      );
  }
};

const AlertColorMap: Record<AlertSeverity, string> = {
  ['high']: '#ad0424',
  ['medium']: '#d7667b',
  ['low']: '#abe4b4',
  ['info']: '#abbfe4'
};

function usePaperStyles(alertSeverity: string) {
  return (theme: Theme) => ({
    borderLeft: `10px solid ${alertSeverity}`,
    bgcolor: alpha(alertSeverity, 0.01),
    padding: theme.spacing(2)
  });
}

export function AlertComponent({ alert }: { alert: Alert }) {
  const title = alertTypeToTitle(alert.type);
  const styles = usePaperStyles(AlertColorMap[alert.severity]);

  return (
    <Paper sx={styles}>
      <Typography variant="h6">{title}</Typography>
      {renderAlertSpecifics(alert)}
    </Paper>
  );
}
