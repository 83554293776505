import { ContentChangeAlert } from '@analyzer/core/lib/analyzer/plugins/changes/content.js';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import * as Diff2Html from 'diff2html';
import 'diff2html/bundles/css/diff2html.min.css';
import SummarizedLink from '../url.js';

function RawDifferencesComponent({ alert }: { alert: ContentChangeAlert }) {
  const code = Diff2Html.html(alert.rawDiff ?? '', {
    outputFormat: 'line-by-line',
    drawFileList: false
  });
  return (
    <>
      <Typography variant="subtitle2">Content Difference</Typography>
      <div dangerouslySetInnerHTML={{ __html: code }}></div>
    </>
  );
}

export function ContentChangeAlertComponent(alert: ContentChangeAlert) {
  return (
    <>
      <Box sx={{ marginBottom: 2 }}>
        <Typography variant="subtitle2">Description</Typography>
        <Typography variant="body2">
          A Content Change Alert signals significant modifications to a website's resource content, which may
          indicate updates or potential security concerns.
        </Typography>
      </Box>

      <Box sx={{ marginBottom: 2 }}>
        <Typography variant="subtitle2">Impact</Typography>
        <Typography variant="body2">The impact of this change is assessed as {alert.severity}.</Typography>
      </Box>

      <Box sx={{ marginBottom: 2 }}>
        <Typography variant="subtitle2">Details</Typography>
        <Typography variant="body2">
          The resource at <SummarizedLink url={alert.url0} title="this location" /> (origin{' '}
          <SummarizedLink url={new URL(alert.url0).origin} title={new URL(alert.url0).origin} />) has been
          altered. Detailed below is a 'diff' showcasing the differences between the prior and current content
          versions, enabling specific modifications to be identified.
        </Typography>
        <Typography variant="body2">
          The diff operation compares the following URLs from application snapshots:
        </Typography>
        <Typography variant="body2">
          <SummarizedLink url={alert.url0} />
        </Typography>
        <Typography variant="body2">
          <SummarizedLink url={alert.url1} />
        </Typography>
        <Typography variant="body2">
          {alert.stable
            ? 'This resource is typically stable; this change marks the first deviation following a sequence of consistent snapshots'
            : 'This resource is frequently updated, suggesting it may be a dynamic endpoint or involve session-specific pages that change upon each visit.'}
          .
        </Typography>
      </Box>

      <RawDifferencesComponent alert={alert} />

      <Box>
        <Typography variant="subtitle2">Details</Typography>
        {alert.changes.map((change, index) => {
          let content;
          switch (change.type) {
            case 'new-html-node':
              content = (
                <>
                  <Typography>A new HTML `{change.name}` node has been added to the page.</Typography>
                  <pre>{change.value}</pre>
                </>
              );
              break;

            case 'new-html-attribute':
              content = (
                <>
                  <Typography>
                    The HTML `{change.element}` node has a new atrribute `{change.name}`.
                  </Typography>
                  <pre>{change.value}</pre>
                </>
              );
              break;
            case 'changed-html-node':
              content = (
                <>
                  <Typography>The contents of HTML `{change.element}` node has changed.</Typography>
                  <pre>{change.patch}</pre>
                </>
              );

              break;

            case 'changed-html-attribute':
              content = (
                <>
                  <Typography>
                    An update modified the attribute `{change.name}` on element `{change.element}`.
                  </Typography>
                  <pre>{change.original}</pre>
                  <pre>{change.new}</pre>
                </>
              );
              break;

            case 'changed-content':
              content = (
                <>
                  <Typography>An update modified the contents of the file.</Typography>
                  <pre>{change.patch}</pre>
                </>
              );

              break;
          }

          return (
            <Box key={index}>
              <Accordion>
                <AccordionSummary>
                  <Typography>
                    Change {index + 1}: {change.type}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>{content}</AccordionDetails>
              </Accordion>
            </Box>
          );
        })}
      </Box>
    </>
  );
}
