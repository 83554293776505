import { Box, CircularProgress, Alert as MuiAlert, Typography } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { analysisReadQuery } from '../api/analysis';
import { testReadQuery } from '../api/test.js';
import {
  decodeObjectId,
  getFormattedDateFromTimestamp,
  getRelativeTimeFromTimestamp
} from '../components/utils.js';
import { AlertComponent } from '../components/alert/view.js';

export function AnalysisView() {
  const { analysisId } = useParams<{ analysisId?: string }>();
  if (!analysisId) throw new Error('Missing parameter: analysisId');

  const analysis = analysisReadQuery(analysisId);
  const test = testReadQuery(analysis.data?.test ?? null);

  if (analysis.isLoading) return <CircularProgress />;
  if (analysis.isError)
    return <MuiAlert severity="error">Error loading analysis: {analysis.error.message}</MuiAlert>;

  if (test.isLoading) return <CircularProgress />;
  if (test.isError) return <MuiAlert severity="error">Error loading test: {test.error.message}</MuiAlert>;

  const alertCounts = ['high', 'medium', 'low', 'info'].map((severity) => ({
    severity,
    count: analysis.data.alerts.filter((alert) => alert.severity === severity).length
  }));

  const context = analysis.data.snapshots.slice().sort().reverse().slice(0, 8);
  const snapshots = analysis.data.snapshots.slice(-2);

  return (
    <>
      <Typography variant="h2">Analysis</Typography>
      <Typography variant="body1">
        Analysis created {getRelativeTimeFromTimestamp(analysis.data.timestamp)} (
        {getFormattedDateFromTimestamp(analysis.data.timestamp)}).
      </Typography>
      <Typography variant="body1">
        This analysis has produced {analysis.data.alerts.length} alert(s)
      </Typography>
      <Typography variant="body1">
        Project <Link to={`/project/${test.data.project}`}>project</Link>
      </Typography>
      <Typography variant="body1">
        Test <Link to={`/test/${analysis.data.test}`}>test</Link>
      </Typography>
      <Typography variant="body1">
        Target <Link to={test.data.target}>{test.data.target}</Link>
      </Typography>

      <Typography variant="h4">Analyzed Snapshots</Typography>
      <Typography variant="body1">The following two snapshots were analyzed:</Typography>
      {snapshots.map((snapshot, index) => (
        <Typography variant="body2" key={snapshot}>
          <Link to={`/snapshot/${snapshot}`}>Snapshot taken {decodeObjectId(snapshot).date}</Link>
        </Typography>
      ))}
      <Typography variant="h4">Historical Data</Typography>
      <Typography variant="body1">
        Used {analysis.data.snapshots.length} snapshots as historical context:
      </Typography>
      {context.map((snapshot, index) => (
        <Typography variant="body2" key={snapshot}>
          <Link to={`/snapshot/${snapshot}`}>Snapshot taken {decodeObjectId(snapshot).date}</Link>
        </Typography>
      ))}
      <Typography variant="h4">Alerts Summary</Typography>
      {alertCounts.map(({ severity, count }) => (
        <Typography key={severity} variant="body1">
          {count} {severity} severity alert(s)
        </Typography>
      ))}
      <Typography variant="h4">Alerts Details</Typography>
      <Box display="flex" flexDirection="column" gap={2}>
        {['high', 'medium', 'low', 'info'].map((severity) =>
          analysis.data.alerts
            .filter((alert) => alert.severity === severity)
            .map((alert, index) => (
              <Box key={index} id={alert.id}>
                <AlertComponent alert={alert} />
              </Box>
            ))
        )}
      </Box>
    </>
  );
}
