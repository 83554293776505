// Implement a provider that allows sharing our `ServiceClient` connection
// through all the application.
import { ServiceClient } from '@analyzer/client';
import { Alert, AlertTitle, Backdrop, CircularProgress, Snackbar, Stack, Typography } from '@mui/material';
import { createContext, useContext, useEffect, useState } from 'react';
import { useAuth } from './AuthProvider';
import { FRONTEND_SERVICE_URL } from '../settings.js';

export const ServiceContext = createContext<ServiceClient | null>(null);

export function ServiceProvider({ children }: any) {
  const [client, setClient] = useState<ServiceClient | null>(null);
  const [error, setError] = useState<string | null>(null);

  const { token } = useAuth();
  if (!token) throw new Error('Missing authentication');

  useEffect(() => {
    const connect = async () => {
      const client = new ServiceClient(FRONTEND_SERVICE_URL, token);
      await client.connect();
      return client;
    };

    connect()
      .then((connectedClient) => {
        setClient(connectedClient);
      })
      .catch((reason: any) => {
        setError((reason as any).toString());
      });
  }, [token]);

  if (!client) {
    return (
      <Backdrop open={true} sx={{ color: '#fff' }}>
        <Stack spacing={2} sx={{ alignItems: 'center' }}>
          <CircularProgress color="inherit" />
          <Typography variant="overline">Connecting to the service ...</Typography>
        </Stack>
      </Backdrop>
    );
  }

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    setError(null);
  };

  return (
    <ServiceContext.Provider value={client}>
      {children}
      <Snackbar
        open={!!error}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          <AlertTitle>There has been an error connecting to the service</AlertTitle>
          {error}
        </Alert>
      </Snackbar>
    </ServiceContext.Provider>
  );
}

export function useService() {
  const serviceContext = useContext(ServiceContext);
  if (!serviceContext) {
    throw new Error('You must provide a ServiceContext via ServiceProvider');
  }

  return serviceContext;
}
