import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import PlayArrow from '@mui/icons-material/PlayArrow';
import Search from '@mui/icons-material/Search';
import {
  Backdrop,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { projectReadQuery } from '../api/project';
import {
  testCreateMutation,
  testDestroyMutation,
  testListQuery,
  testReadQuery,
  testRunMutation,
  testUpdateMutation
} from '../api/test';
import { DeleteTestDialog } from '../components/test/dialogs/delete';
import { EditTestDialog } from '../components/test/dialogs/edit';
import { NewTestDialog } from '../components/test/dialogs/new';
import { RunTestDialog } from '../components/test/dialogs/run';
import { useModalStateHandler } from '../hooks/useModalStateHandler';

type ProjectViewParams = {
  projectId: string;
};

export function ProjectView() {
  const { projectId } = useParams<ProjectViewParams>();
  if (!projectId) throw new Error('Missing parameter');

  const [currentTestId, setCurrentTestId] = useState<string | null>(null);

  const project = projectReadQuery(projectId);
  const testList = testListQuery(projectId);
  const currentTest = testReadQuery(currentTestId);
  const testDestroy = testDestroyMutation();
  const testUpdate = testUpdateMutation();
  const testCreate = testCreateMutation();
  const testRun = testRunMutation();

  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState<string | null>(null);

  const modals = useModalStateHandler({
    'delete-test': false,
    'new-test': false,
    'edit-test': false,
    'run-test': false
  });

  const handleSearchChange = (event: any) => {
    setSearchValue(event.target.value);
  };

  const handleCreateClick = () => {
    modals.open('new-test');
  };

  const handleEditClick = (id: string) => {
    setCurrentTestId(id);
    modals.open('edit-test');
  };

  const handleRunClick = (id: string) => {
    setCurrentTestId(id);
    modals.open('run-test');
  };

  const handleDeleteClick = (id: string) => {
    setCurrentTestId(id);
    modals.open('delete-test');
  };

  const runTestDialog = (
    <RunTestDialog
      test={currentTest.data}
      open={modals.state['run-test']}
      onClose={() => {
        modals.close('run-test');
      }}
      onConfirm={() => {
        testRun
          .mutateAsync({ testId: currentTestId! })
          .then((reportId) => navigate(`/report/${reportId}`))
          .catch(() => {});
      }}
    />
  );

  const newTestDialog = (
    <NewTestDialog
      open={modals.state['new-test']}
      onClose={() => {
        modals.close('new-test');
      }}
      onConfirm={(test) => {
        testCreate
          .mutateAsync({ project: projectId!, ...test })
          .then((testId) => navigate(`/test/${testId}`))
          .catch(() => {});
      }}
    />
  );

  const editTestDialog = (
    <EditTestDialog
      test={currentTest.data}
      open={modals.state['edit-test']}
      onClose={() => {
        modals.close('edit-test');
      }}
      onConfirm={(test) => {
        testUpdate.mutate({ testId: currentTestId!, ...test });
      }}
    />
  );

  const deleteTestDialog = (
    <DeleteTestDialog
      test={currentTest.data}
      open={modals.state['delete-test']}
      onClose={() => {
        modals.close('delete-test');
      }}
      onConfirm={() => {
        testDestroy.mutate({ testId: currentTestId! });
      }}
    />
  );

  return (
    <>
      <Backdrop open={project.isLoading || testList.isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {modals.state['run-test'] && runTestDialog}
      {modals.state['new-test'] && newTestDialog}
      {modals.state['edit-test'] && editTestDialog}
      {modals.state['delete-test'] && deleteTestDialog}

      <Stack spacing={4}>
        <Typography variant="h5">{project.data?.name}</Typography>
        <Paper elevation={1} sx={{ padding: 2 }}>
          <Typography variant="body1">Name: {project.data?.name}</Typography>
          <Typography variant="body1">Description: {project.data?.description}</Typography>
        </Paper>

        <Typography variant="h5">Rules</Typography>
        <Paper elevation={1} sx={{ padding: 2 }}>
          {/* TODO: Render a list of the elements in project.data?.rules */}
        </Paper>

        <Stack spacing={2} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
          <Typography variant="h5">Tests</Typography>
          <Stack spacing={2} direction={'row'}>
            <TextField
              value={searchValue ?? ''}
              onChange={handleSearchChange}
              placeholder="Search Test"
              variant="outlined"
              size="small"
              sx={{ minWidth: '300px' }}
              InputProps={{
                endAdornment: <Search />
              }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleCreateClick}
              sx={{ minWidth: '150px' }}
            >
              Create
            </Button>
          </Stack>
        </Stack>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Target</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {testList.data &&
                testList.data.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>
                      <Link to={`/test/${row.id}`}>{row.test.name}</Link>
                    </TableCell>
                    <TableCell>{row.test.target}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleRunClick(row.id)}>
                        <PlayArrow />
                      </IconButton>
                      <IconButton onClick={() => handleEditClick(row.id)}>
                        <Edit />
                      </IconButton>
                      <IconButton onClick={() => handleDeleteClick(row.id)}>
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </>
  );
}
