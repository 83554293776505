import { Test as BaseTest } from '@analyzer/client';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';

type Test = Pick<BaseTest, 'name' | 'target' | 'actions'>;

interface EditTestDialogProps {
  test?: Test;
  open: boolean;
  onClose: () => void;
  onConfirm: (test: Test) => void;
}

export function EditTestDialog({ test, open, onClose, onConfirm }: EditTestDialogProps) {
  const { register, handleSubmit } = useForm<Test>({ values: test });

  const handleConfirm = handleSubmit(async ({ name, target, actions }) => {
    onConfirm({ name, target, actions });
    onClose();
  });

  const handleClose = () => {
    onClose();
  };

  if (!test) return null;

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Edit test</DialogTitle>
      <form onSubmit={handleConfirm}>
        <DialogContent>
          <Stack spacing={2}>
            <TextField placeholder="Name" inputProps={register('name', { required: true })} />
            <TextField placeholder="Target URL" inputProps={register('target', { required: true })} />
            <TextField placeholder="Actions" inputProps={register('actions')} multiline rows={14} />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Update</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
