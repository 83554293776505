import { Project, Test } from '@analyzer/client';
import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import {
  Backdrop,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  projectCreateMutation,
  projectDestroyMutation,
  projectListQuery,
  projectReadQuery,
  projectUpdateMutation
} from '../api/project';
import { testCreateMutation } from '../api/test.js';
import { DeleteProjectModal } from '../components/project/modals/delete';
import { EditProjectModal } from '../components/project/modals/edit';
import { NewProjectModal, NewProjectsModal } from '../components/project/modals/new';
import { useModalStateHandler } from '../hooks/useModalStateHandler';
import { useService } from '../providers/ServiceProvider.js';

export function ProjectsView() {
  const [currentProjectId, setCurrentProjectId] = useState<string | null>(null);
  const [searchValue, setSearchValue] = useState<string | null>(null);

  const currentProject = projectReadQuery(currentProjectId);
  const projectList = projectListQuery();
  const projectUpdate = projectUpdateMutation();
  const projectDestroy = projectDestroyMutation();
  const projectCreate = projectCreateMutation();
  const testCreate = testCreateMutation();

  const client = useService();
  const navigate = useNavigate();

  const modals = useModalStateHandler({
    'delete-project': false,
    'new-project': false,
    'edit-project': false,
    'bulk-project': false
  });

  const handleSearchChange = (event: any) => {
    setSearchValue(event.target.value);
  };

  const handleCreateClick = () => {
    modals.open('new-project');
  };

  const handleBulkCreateClick = () => {
    modals.open('bulk-project');
  };

  const handleImportClick = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.yaml';
    input.onchange = async (event) => {
      const target = event.target as HTMLInputElement;
      if (!target || !target.files) return;

      const file = target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const readerTarget = e.target as FileReader;
          if (!readerTarget || typeof readerTarget.result !== 'string') return;
          const data = readerTarget.result;
          await client.projectImport(data);
        };

        reader.readAsText(file);
      }
    };

    input.click();
  };

  const handleExportClick = async () => {
    const projects = await client.projectExport();
    const blob = new Blob([projects], { type: 'application/x-yaml' });
    const url = URL.createObjectURL(blob);
    const date = new Date();
    const dateStr = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(
      date.getDate()
    ).padStart(2, '0')}`;

    const link = document.createElement('a');
    link.href = url;
    link.download = `exported-projects-${dateStr}.yaml`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleEditClick = (id: string) => {
    setCurrentProjectId(id);
    modals.open('edit-project');
  };

  const handleDeleteClick = (id: string) => {
    setCurrentProjectId(id);
    modals.open('delete-project');
  };

  const newProjectDialog = (
    <NewProjectModal
      open={modals.state['new-project']}
      onClose={() => {
        modals.close('new-project');
      }}
      onConfirm={async (project, test) => {
        try {
          const projectId = await projectCreate.mutateAsync({ ...project });
          const testId = await testCreate.mutateAsync({ project: projectId!, ...test });
          navigate(`/test/${testId}`);
        } catch (error) {
          console.error(error);
        }
      }}
    />
  );

  const editProjectDialog = (
    <EditProjectModal
      project={currentProject.data}
      open={modals.state['edit-project']}
      onClose={() => {
        modals.close('edit-project');
      }}
      onConfirm={(project: Partial<Project>) => {
        projectUpdate.mutate({ projectId: currentProjectId!, ...project });
      }}
    />
  );

  const deleteProjectDialog = (
    <DeleteProjectModal
      project={currentProject.data}
      open={modals.state['delete-project']}
      onClose={() => {
        modals.close('delete-project');
      }}
      onConfirm={() => {
        projectDestroy.mutate({ projectId: currentProjectId! });
      }}
    />
  );

  const createBulkProjectDialog = (
    <NewProjectsModal
      open={modals.state['bulk-project']}
      onClose={() => {
        modals.close('bulk-project');
      }}
      onConfirm={async (projectTestPairs) => {
        for (const { project, test } of projectTestPairs) {
          try {
            const projectId = await projectCreate.mutateAsync({ ...project });
            await testCreate.mutateAsync({ project: projectId!, ...test });
          } catch (error) {
            console.error(`Error creating bulk reports: ${error}`);
          }
        }
      }}
    />
  );

  return (
    <>
      <Backdrop open={projectList.isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {modals.state['new-project'] && newProjectDialog}
      {modals.state['edit-project'] && editProjectDialog}
      {modals.state['delete-project'] && deleteProjectDialog}
      {modals.state['bulk-project'] && createBulkProjectDialog}

      <Stack spacing={4}>
        <Stack spacing={2} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
          <Typography variant="h5">Tracking {projectList.data?.length} projects</Typography>
          <Stack spacing={2} direction={'row'}>
            <TextField
              value={searchValue ?? ''}
              onChange={handleSearchChange}
              placeholder="Search Project"
              variant="outlined"
              size="small"
              sx={{ minWidth: '300px' }}
              InputProps={{
                endAdornment: <SearchIcon />
              }}
            />
            <Button variant="contained" color="primary" onClick={handleCreateClick}>
              Create
            </Button>
            <Button variant="contained" color="primary" onClick={handleBulkCreateClick}>
              Bulk Create
            </Button>
            <Button variant="contained" color="secondary" onClick={handleImportClick}>
              Import
            </Button>
            <Button variant="contained" color="success" onClick={handleExportClick}>
              Export
            </Button>
          </Stack>
        </Stack>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {projectList.data &&
                projectList.data.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>
                      <Link to={`/project/${row.id}`}>{row.project.name}</Link>
                    </TableCell>
                    <TableCell>{row.project.description}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleEditClick(row.id)}>
                        <Edit />
                      </IconButton>
                      <IconButton onClick={() => handleDeleteClick(row.id)}>
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </>
  );
}
