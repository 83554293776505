import { Snapshot, SnapshotIdentifier, TestIdentifier } from '@analyzer/client';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useService } from '../providers/ServiceProvider';

export function snapshotReadQuery(snapshotId: SnapshotIdentifier) {
  const client = useService();
  return useQuery<Snapshot, Error>({
    queryKey: ['snapshot-read', snapshotId],
    queryFn: async () => {
      return client.snapshotRead(snapshotId);
    }
  });
}

export function snapshotListQuery(testId: TestIdentifier) {
  const client = useService();
  return useQuery<SnapshotIdentifier[], Error>({
    queryKey: ['snapshot-list', testId],
    queryFn: async () => {
      return await client.snapshotList(testId);
    }
  });
}

interface SnapshotCreateArguments {
  testId: TestIdentifier;
}

export function snapshotCreateMutation() {
  const client = useService();
  const queryClient = useQueryClient();

  return useMutation<SnapshotIdentifier, Error, SnapshotCreateArguments>({
    mutationFn: async ({ testId }) => {
      return await client.snapshotCreate(testId);
    },
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries({
        queryKey: ['snapshot-list', variables.testId]
      });
    }
  });
}
